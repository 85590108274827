<template>
  <div class="store-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :selectedEntity="selectedStore"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'stores', label: 'Stores' },
          {
            name: 'storeEditor',
            label: selectedStore.name,
            query: $route.query,
          },
        ]"
        @updateIsEdit="isEdit = $event"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        :hideAddButton="true"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @on-post-save="fetchCurrentStore"
        :canArchive="false"
        :isEditorView="true"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in storeInputs" :key="inputs.id">
              <div
                class="grid-two-column two-columns-layout--responsive-horizontal-space-10"
              >
                <div
                  v-for="input in inputs.inputs"
                  :key="input.id"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="selectedStore[input.id]"
                    @updateModel="selectedStore[input.id] = $event"
                    :inputData="input"
                    :isEdit="isEdit"
                    :entityObject="selectedStore"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Traceability System Begin  -->
      <EditorSection
        title="Traceability System"
        sectionType="traceabilitySystem"
        :bus="bus"
      >
        <MessageBox level="default">
          Contact DF support to change your settings
        </MessageBox>
      </EditorSection>

      <EditorSection
        title="Traceability System"
        sectionType="traceabilitySystem"
        :bus="bus"
        v-if="false"
      >
        <div
          v-for="inputs in traceabilityTypes"
          :key="inputs.id"
          class="two-columns-layout two-columns-layout--responsive"
        >
          <div
            v-for="input in inputs.inputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
        <div
          v-for="inputs in getTraceabilityInputs"
          :key="inputs.id"
          class="two-columns-layout two-columns-layout--responsive"
        >
          <div
            v-for="input in inputs.inputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <BaseInput
              v-model="selectedStore[input.id]"
              :placeholder="input.placeholder"
              :label="input.title"
              :disabled="!isEdit"
              :controlType="input.type"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Traceability System End  -->

      <!-- POS Begin  -->
      <EditorSection title="POS" sectionType="pos" :bus="bus">
        <div class="flex space-between--allow-wrapping">
          <div
            v-for="input in posInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
              customStyle="min-width: 320px; margin-right: 20px"
            />
          </div>
        </div>
      </EditorSection>
      <!-- POS End  -->

      <!-- Points/Loyalty Program Begin  -->
      <EditorSection
        title="Points/Loyalty Program"
        sectionType="ccp"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in filteredLoyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- DF Loyalty Program End  -->

      <!-- Clutch Loyalty Program Begin  -->
      <EditorSection
        v-if="selectedStore.loyaltySystem === 'clutch'"
        title="Clutch Loyalty Program"
        sectionType="clutch"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in clutchLoyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Clutch Loyalty Program End  -->

      <!-- Alpine IQ Loyalty Program Begin  -->
      <EditorSection
        v-if="selectedStore.loyaltySystem === 'alpine'"
        title="Alpine IQ Loyalty Program"
        sectionType="alpineIq"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in alpineIqLoyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Clutch Loyalty Program End  -->

      <!-- Sales Receipt Begin  -->
      <EditorSection
        title="Sales Receipt"
        sectionType="salesReceipt"
        :bus="bus"
      >
        <div>
          <div class="two-columns-layout two-columns-layout--responsive">
            <div class="input-text">
              <div class="reversed-flex-column">
                <FileUploader
                  label="Upload Receipt Logo"
                  allowedTypes="image/png, image/jpeg, image/jpg"
                  @change="(e) => updateImage(e, { type: 'receiptLogo' })"
                  :disabled="!isEdit"
                  iconGroup="fas"
                  iconName="upload"
                  @remove-image="() => removeImage({ type: 'receiptLogo' })"
                  :image="
                    selectedStore.receiptLogo &&
                    (selectedStore.receiptLogo.data ||
                      selectedStore.receiptLogo.secure_url)
                  "
                  id="favicon"
                />
                <div class="input-text__label">Receipt Logo (png)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in salesReciptBeginInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="setProperty(input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Sales Receipt End  -->

      <!-- CanPay integration Begin  -->
      <EditorSection
        title="CanPay Integration"
        sectionType="osTicket"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in canPayIntegrationInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- CanPay integration End  -->

      <!-- GreenCheck integration Begin  -->
      <EditorSection title="GreenCheck Integration" sectionType="osTicket" :bus="bus">
        <div class="two-columns-layout two-columns-layout--responsive">
          <div v-for="input in greenCheckIntegrationInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="lodash.get(selectedStore, input.id)" @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input" :isEdit="isEdit" />
          </div>
        </div>
      </EditorSection>
      <!-- Green Check integration End  -->

      <!-- Leafly integration Begin  -->
      <EditorSection
        title="Leafly Integration"
        sectionType="osTicket"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in leaflyIntegrationInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Leafly integration End  -->

      <!-- NetSuite integration Begin  -->
      <EditorSection
        title="NetSuite Integration"
        sectionType="osTicket"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in netSuiteIntegrationInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- NetSuite integration End  -->

      <!-- QuickBooks integration Begin  -->
      <EditorSection
        title="QuickBooks Integration"
        sectionType="osTicket"
        :bus="bus"
      >
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in quickBooksIntegrationInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
          <div class="quickbooks-buttons">
            <inline-button
              label="Connect Quickbooks Account"
              type="plain-primary responsive-column form-height wide"
              @click="authQuickbooks"
              :disabled="!isEdit"
            />
            <inline-button
              label="Disconnect Quickbooks Account"
              type="plain-secondary responsive-column form-height wide"
              @click="disconnectQuickbooks"
              :disabled="!isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- QuickBooks integration End  -->

      <!-- Weedmaps integration Begin  -->
      <EditorSection title="Weedmaps Integration" sectionType="osTicket" :bus="bus">
        <div class="two-columns-layout two-columns-layout--responsive">
          <div v-for="input in weedmapsIntegrationInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
            <Input :model="lodash.get(selectedStore, input.id)" @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input" :isEdit="isEdit" />
          </div>
        </div>
      </EditorSection>
      <!-- Weedmaps integration End  -->

      <!-- Kiosk integration Begin  -->
      <EditorSection title="Kiosk Settings" sectionType="osTicket" :bus="bus">
        <div class="two-columns-layout two-columns-layout--responsive">
          <div
            v-for="input in kioskInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Kiosk integration End  -->

      <!-- Online Store - General Settings Begin  -->
      <EditorSection
        title="Online Store - General Settings"
        sectionType="osGeneralSettings"
        :bus="bus"
      >
        <div class="flex space-between--allow-wrapping">
          <div
            v-for="input in generalSettingsInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
              customStyle="min-width: 300px; margin-right: 20px"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Online Store - General Settings End  -->

      <!-- Online Store - Ticket Begin  -->
      <EditorSection
        title="Online Store - Ticket"
        sectionType="osTicket"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in ticketInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Online Store - Ticket End  -->

      <!-- Online Store - Messages Begin  -->
      <EditorSection
        title="Online Store - Messages"
        sectionType="osMessages"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in messagesInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
            style="flex-basis: 100%"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Online Store - Messages End  -->

      <!-- Online Store - Aspect Begin  -->
      <EditorSection
        title="Online Store - Aspect"
        sectionType="osAspect"
        :bus="bus"
      >
        <div class="flex space-between--allow-wrapping">
          <div
            v-for="input in aspectInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="setProperty(input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
              style="margin: 10px"
            />
          </div>
          <div class="store-editor__image-upload">
            <!-- image upload logo -->
            <div>
              <div class="two-columns-layout two-columns-layout--responsive">
                <div class="input-text">
                  <div class="reversed-flex-column">
                    <FileUploader
                      label="Upload Logo"
                      allowedTypes="image/png, image/jpeg"
                      @change="(e) => updateImage(e, { type: 'logo' })"
                      :disabled="!isEdit"
                      iconGroup="fas"
                      iconName="upload"
                      @remove-image="() => removeImage({ type: 'logo' })"
                      :image="
                        selectedStore.logo &&
                        (selectedStore.logo.data ||
                          selectedStore.logo.secure_url)
                      "
                      id="logo"
                    />
                    <div class="input-text__label">Store Logo</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end image upload logo -->
            <!-- image upload bundle -->
            <div>
              <div class="two-columns-layout two-columns-layout--responsive">
                <div class="input-text">
                  <div class="reversed-flex-column">
                    <FileUploader
                      label="Upload Bundle"
                      allowedTypes="image/png, image/jpeg"
                      @change="(e) => updateImage(e, { type: 'bundle' })"
                      :disabled="!isEdit"
                      iconGroup="fas"
                      iconName="upload"
                      @remove-image="() => removeImage({ type: 'bundle' })"
                      :image="
                        selectedStore.bundleImage &&
                        (selectedStore.bundleImage.data ||
                          selectedStore.bundleImage.secure_url)
                      "
                      id="bundle"
                    />
                    <div class="input-text__label">Bundle Image</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end image upload bundle -->
            <!-- Image Upload Placeholder -->
            <div>
              <div class="two-columns-layout two-columns-layout--responsive">
                <div class="input-text">
                  <div class="reversed-flex-column">
                    <FileUploader
                      label="Upload Placeholder"
                      allowedTypes="image/png, image/jpeg"
                      @change="(e) => updateImage(e, { type: 'placeholder' })"
                      :disabled="!isEdit"
                      iconGroup="fas"
                      iconName="upload"
                      @remove-image="() => removeImage({ type: 'placeholder' })"
                      :image="
                        selectedStore.productImagePlaceholder &&
                        (selectedStore.productImagePlaceholder.data ||
                          selectedStore.productImagePlaceholder.secure_url)
                      "
                      id="placeholder"
                    />
                    <div class="input-text__label">Product Placeholder</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Image Upload Placeholder -->
            <!-- Upload Favicon -->
            <div>
              <div class="two-columns-layout two-columns-layout--responsive">
                <div class="input-text">
                  <div class="reversed-flex-column">
                    <FileUploader
                      label="Upload Favicon"
                      allowedTypes="image/png, image/jpeg"
                      @change="(e) => updateImage(e, { type: 'favicon' })"
                      :disabled="!isEdit"
                      iconGroup="fas"
                      iconName="upload"
                      @remove-image="() => removeImage({ type: 'favicon' })"
                      :image="
                        selectedStore.favicon &&
                        (selectedStore.favicon.data ||
                          selectedStore.favicon.secure_url)
                      "
                      id="favicon"
                    />
                    <div class="input-text__label">Site Favicon</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Upload Favicon -->
        </div>
      </EditorSection>
      <!-- Online Store - Aspect End  -->

      <!-- Online Store - Carousel  -->
      <EditorSection title="Online Store - Carousel" :bus="bus">
        <MessageBox>
          ☝️
          <a
            href="https://docs.dispensefirst.com/store-settings/carousel.html"
            target="blank"
            >How to set up the Carousel Pictures on the Online Store</a
          ></MessageBox
        >
        <div class="editor-section-button-row margin-bottom-md margin-top-md">
          <inline-button
            label="Add"
            type="plain-primary responsive-column form-height wide"
            @click="addCarouselEntry"
            :disabled="!isEdit"
          />
        </div>
        <draggable v-model="selectedStore.carouselEntries" @start="notifyCarouselImageChanged">
          <div
            v-for="(carouselEntry, i) in selectedStore.carouselEntries"
            :key="carouselEntry._id"
            class="three-columns-layout--border-bottom flex flex--column"
          >
            <div class="flex flex--gap" style="align-items: center">
              <div class="drag">
                <font-awesome-icon :icon="['fas', 'grip-vertical']" />
              </div>
              <div class="reversed-flex-column">
                <FileUploader
                  label="Upload Desktop Image"
                  allowedTypes="image/png, image/jpeg"
                  @change="
                    (e) => {
                      notifyCarouselImageChanged()
                      updateImage(e, {
                        type: 'carousel',
                        entry: carouselEntry,
                        field: 'imageDesktop',
                      })
                    }
                  "
                  :disabled="!isEdit"
                  iconGroup="fas"
                  iconName="upload"
                  @remove-image="
                    () =>
                    {
                      notifyCarouselImageChanged()
                      removeImage({
                        type: 'carousel',
                        entry: carouselEntry,
                        field: 'imageDesktop',
                      })
                    }
                  "
                  :image="
                    carouselEntry.imageDesktop &&
                    (carouselEntry.imageDesktop.data ||
                      carouselEntry.imageDesktop.secure_url)
                  "
                  :id="`imageDesktop${i}`"
                />
                <div class="input-text__label">Desktop Version</div>
              </div>

              <div class="reversed-flex-column">
                <FileUploader
                  label="Upload Mobile Image"
                  allowedTypes="image/png, image/jpeg"
                  @change="
                    (e) =>{
                      notifyCarouselImageChanged()
                      updateImage(e, {
                        type: 'carousel',
                        entry: carouselEntry,
                        field: 'imageMobile',
                      })
                    }
                  "
                  :disabled="!isEdit"
                  iconGroup="fas"
                  iconName="upload"
                  @remove-image="
                    () => {
                      notifyCarouselImageChanged()
                      removeImage({
                        type: 'carousel',
                        entry: carouselEntry,
                        field: 'imageMobile',
                      })
                    }
                  "
                  :image="
                    carouselEntry.imageMobile &&
                    (carouselEntry.imageMobile.data ||
                      carouselEntry.imageMobile.secure_url)
                  "
                  :id="`imageMobile${i}`"
                />
                <div class="input-text__label">Mobile Version</div>
              </div>

              <div class="full-width">
                <Input
                  :model="lodash.get(carouselEntry, 'href')"
                  @updateModel="lodash.set(carouselEntry, 'href', $event)"
                  :inputData="getInput(carouselEntryInputs, 'href')"
                  :isEdit="isEdit"
                />
                <Input
                  :model="lodash.get(carouselEntry, 'shouldOpenInNewTab')"
                  @updateModel="
                    $set(carouselEntry, 'shouldOpenInNewTab', $event)
                  "
                  :inputData="
                    getInput(carouselEntryInputs, 'shouldOpenInNewTab')
                  "
                  :isEdit="isEdit"
                />
              </div>
            </div>
            <div class="flex flex--flex-end">
              <inline-button
                label="Delete"
                type="plain-danger responsive-column form-height wide"
                @click="() => deleteCarouselEntry(i)"
                :disabled="!isEdit"
              />
            </div>
          </div>
        </draggable>
      </EditorSection>
      <!-- Online Store - Carousel  -->

      <!-- Online Store - Email Begin  -->
      <EditorSection
        title="Online Store - Email"
        sectionType="osEmail"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in emailInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
            style="flex-basis: 50%"
          >
            <Input
              :model="selectedStore[input.id]"
              @updateModel="selectedStore[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
              style="margin: 10px"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Online Store - Email End  -->

      <!-- Notes Access Begin  -->
      <EditorSection title="Workstations" :bus="bus">
        <div class="editor-section-button-row margin-bottom-md">
          <inline-button
            label="Add"
            type="plain-primary responsive-column form-height wide"
            @click="addWorkstation"
            :disabled="!isEdit"
          />
        </div>
        <div
          v-for="(workstation, i) in selectedStore.workstations"
          :key="workstation._id"
          class="two-columns-layout--border-bottom"
        >
          <div
            class="two-columns-layout two-columns-layout--responsive two-columns-layout--responsive-horizontal-space-10-forced"
          >
            <div
              class="workstation-container space-between space-between--allow-wrapping"
            >
              <div class="flex-right">
                <BaseBtn
                  iconGroup="fas"
                  iconName="trash"
                  size="sm"
                  @click="() => deleteWorkstation(i)"
                  :disabled="!isEdit"
                  :colorType="1"
                />
              </div>
              <!-- <div v-for="input in workstationInputs" :key="input.id" :class="'input-text ' + input.class"> -->
              <Input
                :model="lodash.get(workstation, 'name')"
                @updateModel="lodash.set(workstation, 'name', $event)"
                :inputData="getInput(workstationInputs, 'name')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'printer.ip')"
                @updateModel="lodash.set(workstation, 'printer.ip', $event)"
                :inputData="getInput(workstationInputs, 'printer.ip')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'printer.type')"
                @updateModel="lodash.set(workstation, 'printer.type', $event)"
                :inputData="getInput(workstationInputs, 'printer.type')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'printer.cut')"
                @updateModel="lodash.set(workstation, 'printer.cut', $event)"
                :inputData="getInput(workstationInputs, 'printer.cut')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'printer.openCashDrawer')"
                @updateModel="
                  lodash.set(workstation, 'printer.openCashDrawer', $event)
                "
                :inputData="
                  getInput(workstationInputs, 'printer.openCashDrawer')
                "
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'isMedical')"
                @updateModel="lodash.set(workstation, 'isMedical', $event)"
                :inputData="getInput(workstationInputs, 'isMedical')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'isRecreational')"
                @updateModel="lodash.set(workstation, 'isRecreational', $event)"
                :inputData="getInput(workstationInputs, 'isRecreational')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'isFulfillment')"
                @updateModel="lodash.set(workstation, 'isFulfillment', $event)"
                :inputData="getInput(workstationInputs, 'isFulfillment')"
                :isEdit="isEdit"
              />
              <Input
                :model="lodash.get(workstation, 'paymentTerminalId')"
                @updateModel="
                  lodash.set(workstation, 'paymentTerminalId', $event)
                "
                :inputData="getPaymentTerminalInput()"
                :isEdit="isEdit"
              />

              <!-- </div> -->
            </div>
          </div>
          <inline-button
            label="Delete"
            class="mobile-only-block"
            type="plain-danger responsive-column form-height wide"
            @click="() => deleteWorkstation(i)"
            :disabled="!isEdit"
          />
        </div>
      </EditorSection>

      <EditorSection title="Payment Terminals" :bus="bus">
        <div
          class="two-columns-layout two-columns-layout--responsive two-columns-layout--responsive-horizontal-space-10-forced"
        >
          <div class="space-between space-between--allow-wrapping">
            <div
              v-for="paymentTerminalIntegrationInput in paymentTerminalIntegrationInputs"
              :key="paymentTerminalIntegrationInput.id"
              class="two-columns-layout--border-bottom"
            >
              <Input
                :model="
                  lodash.get(selectedStore, paymentTerminalIntegrationInput.id)
                "
                @updateModel="
                  lodash.set(
                    selectedStore,
                    paymentTerminalIntegrationInput.id,
                    $event
                  )
                "
                :inputData="paymentTerminalIntegrationInput"
                :isEdit="isEdit"
              />
            </div>
          </div>
        </div>
        <div class="editor-section-button-row margin-bottom-md">
          <inline-button
            label="Add"
            type="plain-primary responsive-column form-height wide"
            @click="addPaymentTerminal"
            :disabled="!isEdit"
          />
        </div>
        <div
          v-for="(paymentTerminal, i) in selectedStore.paymentTerminals"
          :key="paymentTerminal._id"
          class="two-columns-layout--border-bottom"
        >
          <div
            class="two-columns-layout two-columns-layout--responsive two-columns-layout--responsive-horizontal-space-10-forced"
          >
            <div class="space-between space-between--allow-wrapping">
              <div class="flex-right">
                <inline-button
                  label="Delete"
                  class="desktop-only-inline-block"
                  type="plain-danger responsive-column form-height wide"
                  @click="() => deletePaymentTerminal(i)"
                  :disabled="!isEdit"
                />
              </div>
              <div
                v-for="input in paymentTerminalInputs"
                :key="input.id"
                :class="'input-text ' + input.class"
              >
                <Input
                  :model="lodash.get(paymentTerminal, input.id)"
                  @updateModel="lodash.set(paymentTerminal, input.id, $event)"
                  :inputData="input"
                  :isEdit="isEdit"
                />
              </div>
            </div>
          </div>
          <inline-button
            label="Delete"
            class="mobile-only-block"
            type="plain-danger responsive-column form-height wide"
            @click="() => deletePaymentTerminal(i)"
            :disabled="!isEdit"
          />
        </div>
      </EditorSection>
      <!-- Email Setup Begin  -->

      <EditorSection title="Label Printers" :bus="bus">
        <div class="editor-section-button-row margin-bottom-md">
          <inline-button
            label="Add"
            type="plain-primary responsive-column form-height wide"
            @click="addLabelPrinter"
            :disabled="!isEdit"
          />
        </div>
        <div
          v-for="(labelPrinter, i) in selectedStore.labelPrinters"
          :key="labelPrinter._id"
          class="two-columns-layout--border-bottom"
        >
          <div
            class="two-columns-layout two-columns-layout--responsive two-columns-layout--responsive-horizontal-space-10-forced"
          >
            <div class="space-between space-between--allow-wrapping">
              <div class="flex-right">
                <inline-button
                  label="Delete"
                  class="desktop-only-inline-block"
                  type="plain-danger responsive-column form-height wide"
                  @click="() => deleteLabelPrinter(i)"
                  :disabled="!isEdit"
                />
              </div>
              <div
                v-for="input in labelPrinterInputs"
                :key="input.id"
                :class="'input-text ' + input.class"
              >
                <Input
                  :model="lodash.get(labelPrinter, input.id)"
                  @updateModel="lodash.set(labelPrinter, input.id, $event)"
                  :inputData="input"
                  :isEdit="isEdit"
                />
              </div>
            </div>
          </div>
          <inline-button
            label="Delete"
            class="mobile-only-block"
            type="plain-danger responsive-column form-height wide"
            @click="() => deleteLabelPrinter(i)"
            :disabled="!isEdit"
          />
        </div>
      </EditorSection>
      <!-- Email Setup Begin  -->
      <EditorSection title="Email Setup" sectionType="emailSetup" :bus="bus">
        <div class="space-between">
          <Input
            v-show="selectedStore['emailProvider'] == 'sendgrid'"
            :inputData="getEmailInput('emailApiKey')"
            :isEdit="isEdit"
            :model="selectedStore['emailApiKey']"
            @updateModel="selectedStore['emailApiKey'] = $event"
          />
          <Input
            v-show="selectedStore['emailProvider'] != 'sendgrid'"
            :inputData="getEmailInput('emailServer')"
            :isEdit="isEdit"
            :model="selectedStore['emailServer']"
            @updateModel="selectedStore['emailServer'] = $event"
          />
          <Input
            v-show="selectedStore['emailProvider'] != 'sendgrid'"
            :inputData="getEmailInput('emailPort')"
            :isEdit="isEdit"
            :model="selectedStore['emailPort']"
            @updateModel="selectedStore['emailPort'] = $event"
          />
          <Input
            :inputData="getEmailInput('emailUser')"
            :isEdit="isEdit"
            :model="selectedStore['emailUser']"
            @updateModel="selectedStore['emailUser'] = $event"
          />
          <Input
            v-show="selectedStore['emailProvider'] != 'sendgrid'"
            :inputData="getEmailInput('emailPassword')"
            :isEdit="isEdit"
            :model="selectedStore['emailPassword']"
            @updateModel="selectedStore['emailPassword'] = $event"
          />
          <Input
            :inputData="getEmailInput('emailProvider')"
            :isEdit="isEdit"
            :model="selectedStore['emailProvider']"
            @updateModel="selectedStore['emailProvider'] = $event"
          />
        </div>
      </EditorSection>

      <EditorSection
        title="External API Settings"
        sectionType="osGeneralSettings"
        :bus="bus"
      >
        <div class="flex space-between--allow-wrapping">
          <div
            v-for="input in externalAPIInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
          <Input
              :model="lodash.get(selectedStore, input.id)"
              @updateModel="lodash.set(selectedStore, input.id, $event)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>

      <!-- Email Setup End  -->
      <EditorSection
        :title="`About ${selectedStore.name || ''}`"
        sectionType="about"
        v-if="selectedStore._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedStore" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Constants from "@/resources/Constants";
import _ from "lodash";
import FileUploader from "@/components/FileUploader/FileUploader.vue";
import Entities from "@/resources/Entities";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import MessageBox from "@/components/MessageBox/MessageBox.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { quickbooks } from "./integrations";

import {
  storeInputs,
  traceabilityInputs,
  posInputs,
  loyaltyProgramInputs,
  clutchLoyaltyProgramInputs,
  alpineIqLoyaltyProgramInputs,
  salesReciptBeginInputs,
  generalSettingsInputs,
  externalAPIInputs,
  ticketInputs,
  messagesInputs,
  aspectInputs,
  emailInputs,
  pickupSchedulerInputs,
  selectedSchedulerDayInput,
  timeSlotInputs,
  schedulerSlotsInput,
  columnHeaders,
  filterCriteria,
  pageHeader,
  defaultSelectedStore,
  workstationInputs,
  carouselEntryInputs,
  paymentTerminalInputs,
  labelPrinterInputs,
  emailSetUpInputs,
  traceabilityTypes,
  leaflyIntegrationInputs,
  weedmapsIntegrationInputs,
  greenCheckIntegrationInputs,
  quickBooksIntegrationInputs,
  netSuiteIntegrationInputs,
  canPayIntegrationInputs,
  paymentTerminalIntegrationInputs,
  kioskInputs,
} from "./inputs";

export default {
  name: "storeEditor",
  data() {
    return {
      Constants: Constants,
      selectedPickupWindowAccountType: undefined,
      selectedPickupSchedulerAccountType: undefined,
      selectedSchedulerDay: { label: "Monday", code: "monday" },
      storeInputs,
      traceabilityInputs,
      traceabilityTypes,
      posInputs,
      loyaltyProgramInputs,
      clutchLoyaltyProgramInputs,
      alpineIqLoyaltyProgramInputs,
      salesReciptBeginInputs,
      generalSettingsInputs,
      externalAPIInputs,
      leaflyIntegrationInputs,
      weedmapsIntegrationInputs,
      greenCheckIntegrationInputs,
      quickBooksIntegrationInputs,
      netSuiteIntegrationInputs,
      kioskInputs,
      canPayIntegrationInputs,
      aspectInputs,
      ticketInputs,
      messagesInputs,
      emailInputs,
      pickupSchedulerInputs,
      selectedSchedulerDayInput,
      timeSlotInputs,
      schedulerSlotsInput,
      isEdit: false,
      isCollapsed: true,
      bus: new Vue(),
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.STORE,
      defaultSelectedStore,
      pageHeader,
      lodash: _,
      workstationInputs,
      carouselEntryInputs,
      paymentTerminalInputs,
      labelPrinterInputs,
      emailSetUpInputs,
      paymentTerminalIntegrationInputs,
    };
  },
  components: {
    PageHeader,
    About,
    FileUploader,
    EditorSection,
    Input,
    BaseBtn,
    BaseInput,
    MessageBox,
    draggable,
    FontAwesomeIcon,
  },
  mounted: function () {
    this.setPageTitle("Store");
    if (this.$route.query.id) {
      this.getStoreById({
        _id: this.$route.query.id,
      });
    } else {
      // new element
      this.isEdit = true;
    }
    this.getRooms({ filter: {} });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getStoreById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "storeModule/loadingStatus",
      store: "storeModule/store",
      rooms: "productModule/rooms",
    }),
    selectedStore: {
      get() {
        this.setPageTitle(`Store ${this.store.name}`);
        return this.store;
      },
      set(entity) {
        return entity;
      },
    },
    accountTypeOptions: function () {
      if (this.selectedStore.isMedical && this.selectedStore.isRecreational) {
        return Constants.listOfCustomerExclusive;
      }
      if (this.selectedStore.isMedical) {
        return [
          {
            label: "Medical",
            code: "medical",
          },
        ];
      }
      if (this.selectedStore.isRecreational) {
        return [
          {
            label: "Recreational",
            code: "recreational",
          },
        ];
      }
      return [];
    },
    getTraceabilityInputs: function () {
      const result = this.traceabilityInputs.filter(
        (input) =>
          input.id === "traceabilitySystem" ||
          input.id === this.selectedStore.traceabilitySystem ||
          input.id === this.selectedStore.traceabilitySystem?.code
      );
      return result;
    },
    filteredLoyaltyProgramInputs() {
      // if a loyalty system different than dispense first is selected
      // then show only the loyalty system selector
      if (this.selectedStore.loyaltySystem !== "dispense-first") {
        return [...this.loyaltyProgramInputs.slice(0, 6), ...this.loyaltyProgramInputs.slice(8, 10), this.loyaltyProgramInputs[17]];
      }
      return this.loyaltyProgramInputs;
    },
  },
  methods: {
    ...mapActions({
      getStoreById: "storeModule/getStoreById",
      saveStore: "storeModule/saveStore",
      deleteStore: "storeModule/deleteStore",
      getRooms: "productModule/getRooms",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getCurrentStore: "storeModule/getCurrentStore",
    }),
    getPaymentTerminalInput() {
      const input = this.getInput(this.workstationInputs, "paymentTerminalId");
      const paymentTerminals = this.selectedStore.paymentTerminals;
      const formattedPaymentTerminal = paymentTerminals.map((t) => {
        return {
          label: t.name,
          code: t._id,
        };
      });
      input.options = formattedPaymentTerminal;
      return input;
    },
    getInput(inputList, field) {
      return inputList.find((i) => i.id == field);
    },
    getEmailInput(field) {
      return emailSetUpInputs.find((i) => i.id == field);
    },
    performSave() {
      if (this.loadingStatus.saveStore) {
        return;
      }

      if (!this.validateEntityBeforeSaving(this.selectedStore)) {
        return;
      }

      const storeToSave = this.normalizeEntityBeforeSaving(this.selectedStore);

      this.saveStore({
        store: storeToSave,
        positiveCallback: (store) => {
          this.setPageTitle(`Store ${store.name}`);

          if (this.selectedStore._id === "NEW") {
            router.push({
              name: "storeEditor",
              query: { id: store._id },
            });
            return;
          }
          this.selectedStore = this.prefillEntityBeforeRendering(store);
          this.isEdit = false;
        },
      });
    },
    prefillEntityBeforeRendering(entity) {
      entity.medicalPickupScheduler.forEach((d) => {
        let duration = _.find(
          Constants.listOfDurations,
          (t) => t.code === d.timeSlotDuration
        );
        d.timeSlotDuration = duration;
        duration = _.find(
          Constants.listOfDurations,
          (t) => t.code === d.leadway
        );
        d.leadway = duration;
      });
      entity.recreationalPickupScheduler.forEach((d) => {
        let duration = _.find(
          Constants.listOfDurations,
          (t) => t.code === d.timeSlotDuration
        );
        d.timeSlotDuration = duration;
        duration = _.find(
          Constants.listOfDurations,
          (t) => t.code === d.leadway
        );
        d.leadway = duration;
      });
      return entity;
    },
    validatePickuptime(pickupTime) {
      let isPickupTimeWellFormed = true;

      pickupTime.forEach((t) => {
        if (t.openTime.match(/^\d\d:\d\d$/g) === null) {
          isPickupTimeWellFormed = false;
          return;
        }
        let hours = parseInt(t.openTime[0] + t.openTime[1]);
        let minutes = parseInt(t.openTime[3] + t.openTime[4]);

        if (hours > 23 || minutes > 59) {
          isPickupTimeWellFormed = false;
          return;
        }

        hours = parseInt(t.closeTime[0] + t.closeTime[1]);
        minutes = parseInt(t.closeTime[3] + t.closeTime[4]);

        if (hours > 23 || minutes > 59) {
          isPickupTimeWellFormed = false;
          return;
        }

        hours = parseInt(t.leadway[0] + t.leadway[1]);
        minutes = parseInt(t.leadway[3] + t.leadway[4]);

        if (hours > 23 || minutes > 59) {
          isPickupTimeWellFormed = false;
        }
      });

      return isPickupTimeWellFormed;
    },
    validateEntityBeforeSaving(entityToValidate) {
      if (
        !this.validatePickuptime(
          entityToValidate.recreationalPickupWindowsPerDay
        )
      ) {
        this.setMessage({
          text: "Check the times in the recreational pickup windows. Time must be in the format hh:mm in 24h clock.",
          type: "error",
        });
        return false;
      }

      if (
        !this.validatePickuptime(entityToValidate.medicalPickupWindowsPerDay)
      ) {
        this.setMessage({
          text: "Check the times in the medical pickup windows. Time must be in the format hh:mm in 24h clock.",
          type: "error",
        });
        return false;
      }

      if (
        this.selectedStore.isRecreationalPickupSchedulerEnabled &&
        this.selectedStore.isRecreationalPickupWindowEnabled
      ) {
        this.setMessage({
          text: "Only one pick up method can be selected between Scheduler and Window for recreational.",
          type: "error",
        });
        return false;
      }

      if (
        this.selectedStore.isMedicalPickupWindowEnabled &&
        this.selectedStore.isMedicalPickupSchedulerEnabled
      ) {
        this.setMessage({
          text: "Only one pick up method can be selected between Scheduler and Window for medical.",
          type: "error",
        });
        return false;
      }

      if (
        this.selectedStore.carouselEntries &&
        this.selectedStore.carouselEntries.length > 0
      ) {
        const entryWithoutImages = this.selectedStore.carouselEntries.find(
          (e) =>
            (!e.imageDesktop || _.isEmptyObject(e.imageDesktop)) &&
            (!e.imageMobile || _.isEmptyObject(e.imageMobile))
        );

        if (entryWithoutImages) {
          this.setMessage({
            text: "All carousel entries must have at least one picture.",
            type: "error",
          });
          return false;
        }
      }

      return true;
    },
    normalizeEntityBeforeSaving(store) {
      const entity = JSON.parse(JSON.stringify(store));
      if (entity.state && entity.state.abbreviation) {
        entity.state = entity.state.abbreviation;
      }
      if (entity.traceabilitySystem && entity.traceabilitySystem.code) {
        entity.traceabilitySystem = entity.traceabilitySystem.code;
      }
      if (entity.endOfFiscalYearMonth && entity.endOfFiscalYearMonth.code) {
        entity.endOfFiscalYearMonth = entity.endOfFiscalYearMonth.code;
      }
      if (entity.endOfFiscalYearDay && entity.endOfFiscalYearDay.code) {
        entity.endOfFiscalYearDay = entity.endOfFiscalYearDay.code;
      }
      if (entity.defaultDisposalRoom === "") {
        entity.defaultDisposalRoom = undefined;
      }
      entity.medicalPickupScheduler.forEach((d) => {
        if (d.timeSlotDuration && d.timeSlotDuration.code) {
          d.timeSlotDuration = d.timeSlotDuration.code;
        }
        if (d.leadway && d.leadway.code) {
          d.leadway = d.leadway.code;
        }
      });
      entity.recreationalPickupScheduler.forEach((d) => {
        if (d.timeSlotDuration && d.timeSlotDuration.code) {
          d.timeSlotDuration = d.timeSlotDuration.code;
        }
        if (d.leadway && d.leadway.code) {
          d.leadway = d.leadway.code;
        }
      });
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteStore) {
        return;
      }

      const storeToDelete = this.normalizeEntityBeforeSaving(
        this.selectedStore
      );

      this.deleteStore({
        store: storeToDelete,
        positiveCallback: () => {
          router.push({
            name: "stores",
          });
        },
      });
    },
    generateTimeSlots() {
      if (!this.selectedPickupSchedulerAccountType) {
        return;
      }

      const timeSlots = [];
      const minutesInADay = 1440;
      let currentMinutes = 0;
      let timeSlotDuration;
      let schedulerDay;

      if (this.selectedPickupSchedulerAccountType.code === "medical") {
        schedulerDay = _.find(
          this.selectedStore.medicalPickupScheduler,
          (s) => s.day === this.selectedSchedulerDay.code
        );
      }
      if (this.selectedPickupSchedulerAccountType.code === "recreational") {
        schedulerDay = _.find(
          this.selectedStore.recreationalPickupScheduler,
          (s) => s.day === this.selectedSchedulerDay.code
        );
      }

      if (schedulerDay) {
        timeSlotDuration = schedulerDay.timeSlotDuration;
      } else {
        timeSlotDuration = { label: "30 min", code: "30" };
      }

      while (currentMinutes < minutesInADay) {
        const newSlot = {
          label: "",
          isEnabled: false,
        };

        let hour = Math.round(currentMinutes / 60);
        hour = hour < 10 ? "0" + hour : hour;
        let minutes = Math.round(currentMinutes % 60);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        newSlot.label = hour + ":" + minutes;

        timeSlots.push(newSlot);

        if (this.selectedPickupSchedulerAccountType.code === "medical") {
          currentMinutes = currentMinutes * 1 + timeSlotDuration.code * 1;
        }
        if (this.selectedPickupSchedulerAccountType.code === "recreational") {
          currentMinutes = currentMinutes * 1 + timeSlotDuration.code * 1;
        }
      }

      if (this.selectedPickupSchedulerAccountType.code === "medical") {
        const dayInScheduler = _.find(
          this.selectedStore.medicalPickupScheduler,
          (s) => s.day === this.selectedSchedulerDay.code
        );
        if (dayInScheduler) {
          dayInScheduler.timeSlots = timeSlots;
        } else {
          this.selectedStore.medicalPickupScheduler.push({
            day: this.selectedSchedulerDay.code,
            timeSlots: timeSlots,
            timeSlotDuration,
          });
        }
      }
      if (this.selectedPickupSchedulerAccountType.code === "recreational") {
        const dayInScheduler = _.find(
          this.selectedStore.recreationalPickupScheduler,
          (s) => s.day === this.selectedSchedulerDay.code
        );
        if (dayInScheduler) {
          dayInScheduler.timeSlots = timeSlots;
        } else {
          this.selectedStore.recreationalPickupScheduler.push({
            day: this.selectedSchedulerDay.code,
            timeSlots: timeSlots,
            timeSlotDuration,
          });
        }
      }
    },
    updateImage(e, options) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0], options);
    },
    createImage(file, options) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (options.type === "logo") {
          if (!this.selectedStore.logo) {
            this.selectedStore.logo = {};
          }
          this.selectedStore.logo.data = e.target.result;
        }

        if (options.type === "bundle") {
          if (!this.selectedStore.bundleImage) {
            this.selectedStore.bundleImage = {};
          }
          this.selectedStore.bundleImage.data = e.target.result;
        }

        if (options.type === "placeholder") {
          if (!this.selectedStore.productImagePlaceholder) {
            this.selectedStore.productImagePlaceholder = {};
          }
          this.selectedStore.productImagePlaceholder.data = e.target.result;
        }

        if (options.type === "favicon") {
          if (!this.selectedStore.favicon) {
            this.selectedStore.favicon = {};
          }
          this.selectedStore.favicon.data = e.target.result;
        }

        if (options.type === "receiptLogo") {
          if (!this.selectedStore.receiptLogo) {
            this.selectedStore.receiptLogo = {};
          }
          this.selectedStore.receiptLogo.data = e.target.result;
        }

        if (options.type === "carousel") {
          options.entry[options.field] = { data: e.target.result };
        }

        this.$forceUpdate();
      };
      reader.readAsDataURL(file);
    },
    notifyCarouselImageChanged: function(){
      this.selectedStore.carouselImagesChanged = true
    },
    removeImage: function (options) {
      if (options.type === "logo") {
        this.selectedStore.logo = {};
      }

      if (options.type === "bundle") {
        this.selectedStore.bundleImage = {};
        this.selectedStore.bundleImageThumbnail = {};
      }

      if (options.type === "placeholder") {
        this.selectedStore.productImagePlaceholder = {};
        this.selectedStore.productImagePlaceholderThumbnail = {};
      }

      if (options.type === "favicon") {
        this.selectedStore.favicon = {};
      }

      if (options.type === "receiptLogo") {
        this.selectedStore.receiptLogo = {};
      }

      if (options.type === "carousel") {
        options.entry[options.field] = {};
      }

      this.$forceUpdate();
    },
    addNewStore: function () {
      this.isEdit = true;
      this.selectedStore = this.defaultSelectedStore;
      this.selectedStore._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedStore[entity] = item;
    },
    toggleEdit() {
      if (!(this.selectedStore._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedStore._id === "NEW") {
        this.isEdit = true;
      }
    },
    authQuickbooks() {
      const isSandbox = this.selectedStore.quickBooks.isSandboxEnvironment
      const storeId = this.selectedStore._id

      quickbooks.authorize(isSandbox, storeId)
    },
    disconnectQuickbooks() {
      const storeId = this.selectedStore._id
      quickbooks.disconnectAccount(storeId)
    },
    addPaymentTerminal() {
      if (!this.selectedStore.paymentTerminals) {
        this.selectedEmployee.paymentTerminals = [];
      }
      const newPaymentTermianl = {
        name: "",
        registerId: "",
      };
      this.selectedStore.paymentTerminals.unshift(newPaymentTermianl);
    },
    deletePaymentTerminal(i) {
      this.selectedStore.paymentTerminals.splice(i, 1);
    },
    addWorkstation() {
      if (!this.selectedStore.workstations) {
        this.selectedEmployee.workstations = [];
      }
      const newWorkstation = {
        name: "",
        printer: {
          ip: "",
          cut: true,
          openCashDrawer: true,
        },
        _id: "NEW",
      };
      this.selectedStore.workstations.unshift(newWorkstation);
    },
    addCarouselEntry() {
      if (!this.selectedStore.carouselEntries) {
        this.selectedEmployee.carouselEntries = [];
      }
      const newCarouselEntry = {
        href: "",
        _id: "NEW",
      };
      this.notifyCarouselImageChanged()
      this.selectedStore.carouselEntries.unshift(newCarouselEntry);
    },
    deleteCarouselEntry(i) {
      this.notifyCarouselImageChanged()
      this.selectedStore.carouselEntries.splice(i, 1);
    },
    addLabelPrinter() {
      if (!this.selectedStore.labelPrinters) {
        this.selectedEmployee.labelPrinters = [];
      }
      const newLabelPrinter = {
        name: "",
        printer: {
          ip: "",
        },
        _id: "NEW",
      };
      this.selectedStore.labelPrinters.unshift(newLabelPrinter);
    },
    deleteWorkstation(i) {
      this.selectedStore.workstations.splice(i, 1);
    },
    deleteLabelPrinter(i) {
      this.selectedStore.labelPrinters.splice(i, 1);
    },
    getProperty(id) {
      return _.get(this.selectedStore, id);
    },
    setProperty(id, $event) {
      _.set(this.selectedStore, id, $event);
    },
    fetchCurrentStore() {
      this.getCurrentStore();
      this.selectedStore.carouselImagesChanged = false
    },
  },
};
</script>

<style lang="scss" scoped>
.store-editor {
  &__image-upload {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

.scheduler {
  > div {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

.image-preview {
  width: 250px;
  border-radius: 2px;
}

.workstation-container .input-wrapper {
  min-width: auto !important;
}

.drag {
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: move;
  }
}

.quickbooks-buttons {
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  padding-bottom: .5rem;
}
</style>
