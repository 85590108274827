import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  loadingStatus: {
    lookup: false,
    enroll: false
  },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setLoyaltyStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
};

const getters = {
  loadingStatus: (state) => state.loadingStatus,
  loadingEnrollStatus: (state) => state.loadingStatus,
};

const actions = {
  lookup: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "lookup", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.loyalty.lookup}`,
      { filters: payload.searchFields },
      function (response, error) {
        commit("setLoadingStatus", { api: "lookup", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
        }
      }
    );
  },
  getPointsCashConversionRate: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getPointsCashConversionRate", status: true });
    HttpService.get(
      `${Apis.backendBaseUrl}${Apis.loyalty.getPointsCashConversionRate}`,
      function (response, error) {
        commit("setLoadingStatus", { api: "getPointsCashConversionRate", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
        }
      }
    );
  },
  reenrollCustomer: ({ commit, state, dispatch }, payload) => {
    commit("setLoyaltyStatus", { api: "enroll", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.loyalty.reenroll}`,
      {...payload.payload},
      function (response, error) {
        commit("setLoyaltyStatus", { api: "enroll", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
        }
      }
    );
  },
  customerOptOut: ({ commit, state, dispatch }, payload) => {
    commit("setLoyaltyStatus", { api: "optOut", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.loyalty.optOut}`,
      {...payload.payload},
      function (response, error) {
        commit("setLoyaltyStatus", { api: "optOut", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
        }
      }
    );
  },
  getAvailableDiscounts: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getAvailableDiscounts", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.loyalty.getAvailableDiscounts}`,
      {...payload.body},
      function (response, error) {
        commit("setLoadingStatus", { api: "getAvailableDiscounts", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
        }
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
