import { isInventoryManager } from '@/lib/rolesHelper'
// Page Header
export const listPageHeader = {
  title: "Inventory Count",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "InventoryCountEditor",
};

export const defaultSelectedInventoryCount = {
  _id: "NEW",
  createdAt: null,
  createdBy: null,
  items: []
};

export const filterCriteria = [
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  { label: "Created On/After", code: "createdAt", field: { type: "date", label: "Created At" }, operation(val) {
        return { $gte: val};
      }, },
  { label: "Count Type", code: "compareTo.name", field: { type: "select", label: "Count Type", options: [
    { label: "All", value: "All", },
    { label: "Working Inventory", value: "Working Inventory", },
    { label: "Vault", value: "Vault", },
  ] } },
  { label: "Product Type", code: "compareTo.productType", field: { type: "select", label: "Product Type", options: [
    { label: "all", value: "all", },
    { label: "cannabis", value: "cannabis", },
    { label: "non-cannabis", value: "non-cannabis", },
  ] } },
];

export const columnHeaders = [
  {
    id: "archive",
    type: "button",
    action: "removeItem",
    position: "sticky",
    iconName: "archive",
    ariaLabel: "Archive",
    disabled: (item, user, store) =>{
      const manager = isInventoryManager(user, store._id);
      return item.isClosed || !manager;
    }
  },
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
    disabled: (item, user, store) =>{
      const manager = isInventoryManager(user, store._id);
      return item.isArchived && !manager;
    }
  },
  {
    title: "Id",
    id: "_id",
    size: "xlarge",
  },
  {
    id: "createdAt",
    placeholder: "Created Date",
    type: "date-extended",
    title: "Created Date",
    size: "xlarge",
  },
  {
    id: "closedAt",
    placeholder: "Closed Date",
    type: "date-extended",
    title: "Closed Date",
    size: "xlarge",
  },
  {
    title: "Created By",
    id: "createdBy.name",
    nestedId: "createdBy.name",
    size: "auto"
  },
  {
    title: "Count Type",
    id: "compareTo.name",
    nestedId: "compareTo.name",
    size: "medium"
  },
  {
    title: "Product Type",
    id: "compareTo.productType",
    nestedId: "compareTo.productType",
    size: "medium"
  },
  {
    id: "isArchived",
    checkboxLabel: "Archived",
    title: "Archived",
    type: "checkbox",
    size: "small"
  },
  {
    id: "isClosed",
    checkboxLabel: "Closed",
    title: "Closed",
    type: "checkbox",
    size: "small"
  },
];
