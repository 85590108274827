<template>
  <div class="customer-form">
    <MessageBox level="default">
      Enroll customer in loyalty program. A new customer will be created, or updated if they already exist. The user is
      identified by phone number or email on the loyalty system, and by email on
      DispenseFirst.
    </MessageBox>
    <div class="customer-form__fields">
      <div class="customer-form__input-container">
        <span class="input-text__label">Name *</span>
        <input
          v-model="customer.name"
          placeholder="Name"
          class="input-text__field"
        />
      </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">Last Name *</span>
        <input
          v-model="customer.lastName"
          placeholder="Last Name"
          class="input-text__field"
        />
      </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">Email *</span>
        <input
          v-model="customer.email"
          placeholder="Email"
          class="input-text__field"
        />
      </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">Phone *</span>
        <TheMask
          placeholder="Phone"
          :masked="false"
          v-model="customer.phone"
          class="input-text__field"
          mask="(###) #######"
        />
      </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">State *</span>
        <v-select
          v-model="customer.state"
          class="input-text__select"
          placeholder="State"
          :options="Constants.listOfStates"
          :label="'label'"
          :clearable="true"
        />
      </div>
      <div style="display: flex; gap: 1rem;">
        <div class="customer-form__input-container">
          <span class="input-text__label">Date of Birth</span>
          <Calendar
            v-model="customer.dob"
            placeholder="mm/dd/yyyy"
            :showTime="false"
            :monthNavigator="false"
          />
        </div>
        <div class="customer-form__input-container">
        <span class="input-text__label">Is Medical?</span>
        <CheckBox
          :value="customer.isMedical"
          @click="(e) => customer.isMedical = !customer.isMedical"
        />
      </div>
    </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">Zip Code</span>
        <input
          v-model="customer.zip"
          placeholder="Zip Code"
          class="input-text__field"
        />
      </div>
      <div class="customer-form__input-container">
        <span class="input-text__label">License Number</span>
        <input
          v-model="customer.licenseNumber"
          placeholder="License Number"
          class="input-text__field"
        />
      </div>
      <div></div>
      <div></div>
    </div>
    <div>
      <BaseBtn
        v-shortkey="['enter']"
        @shortkey="performCreateCustomer"
        :isLoading="loadingStatus.createCustomer"
        iconGroup="fas"
        iconName="save"
        label="Save"
        @click="performCreateCustomer"
      />
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import MessageBox from "@/components/MessageBox/MessageBox.vue";
import { mapGetters, mapActions } from "vuex";
import Calendar from "primevue/calendar";
import Constants from "@/resources/Constants";
import { TheMask } from "vue-the-mask";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";

export default {
  name: "CustomerForm",
  components: {
    BaseBtn,
    Calendar,
    TheMask,
    MessageBox,
    CheckBox,
  },
  data() {
    return {
      customer: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        state: "",
        zip: "",
        licenseNumber: "",
        isMedical: false,
      },
      Constants,
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    licenseNumber: {
      type: String,
      default: "",
    },
    state: {
      type: Object,
    },
    email: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  created() {
    this.customer.name = this.name;
    this.customer.lastName = this.lastName;
    this.customer.licenseNumber = this.licenseNumber;
    this.customer.state = this.state;
    this.customer.email = this.email;
    this.customer.phone = this.phone;
  },
  computed: {
    ...mapGetters({
      loadingStatus: "customerModule/loadingStatus",
    }),
  },
  methods: {
    ...mapActions({
      createCustomer: "customerModule/createCustomer",
    }),
    performCreateCustomer() {
      const customer = JSON.parse(JSON.stringify(this.customer));
      if (customer.state?.code) {
        customer.state = customer.state.code;
      }
      this.createCustomer({
        customer,
        positiveCallback: (result) => {
          this.$emit("customerCreation", result.customer);
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_customerForm.scss";
</style>
