// Special Pricing Editor
import * as Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";
import {
  filterCriteria as groupFilterCriteria,
  searchColumnHeaders as groupColumnHeaders,
} from "../Group/inputs";

import {
  filterCriteria as supplierFilterCriteria,
  searchColumnHeaders as supplierColumnHeaders,
} from "../Supplier/inputs";

import {
  filterCriteria as brandFilterCriteria,
  searchColumnHeaders as brandColumnHeaders,
} from "../Brand/inputs";

// Page Header
export const pageHeader = {
  title: "Special Pricing",
  isEditorView: true,
  cancelPath: "specialPricings",
  addPath: "specialPricingEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Special Pricing",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "specialPricingEditor",
};

export const filterCriteria = [
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  { label: "Brand", code: "brandName", field: { type: "input", label: "Brand" }, isPartial: true, },
  { label: "Group", code: "groupName", field: { type: "input", label: "Group" }, isPartial: true, },
  { label: "Supplier", code: "supplierName", field: { type: "input", label: "Supplier" }, isPartial: true, },
];

// Columns (in order):
// Sale Type
// Active
// Size
// Group
// Quantity
// Price
// Quantity Limit
// Where minimum price is [Minimum Price] and maximum price is [Maximum Price]
// Publish to Online Store
// Apply Date Range (should be Date, not Data)
// Start Date
// End Date
export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
    position: "sticky",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Online Store",
    id: "isPublishedToOnlineStore",
    type: "checkbox",
  },
  {
    title: "Sale Type",
    id: "saleType",
    size: "large",
  },
  {
    title: "Size",
    id: "size",
    size: "medium",
  },
  {
    title: "Group",
    id: "group.name",
    size: "large",
  },
  {
    title: "Supplier",
    id: "supplier.name",
    size: "large",
  },
  {
    title: "Brand",
    id: "brand.name",
    size: "large",
  },
  {
    title: "Day of Week",
    id: "dayOfWeek",
    type: "select",
    options: Constants.dayOfWeek,
  },
  {
    title: "Quantity",
    id: "quantity",
  },
  {
    title: "Percentage",
    id: "percentageAmount",
    suffix: "%",
    size: "medium",
  },
  {
    title: "Price",
    id: "price",
    prefix: "$",
    size: "medium",
  },
  {
    title: "Quantity Limit",
    id: "quantityLimit",
  },
  {
    title: "Minimum Price",
    id: "minimumPrice",
    prefix: "$",
  },
  {
    title: "Maximum Price",
    id: "maximumPrice",
    prefix: "$",
  },
  {
    title: "Apply Date Range",
    id: "applyDateRange",
    type: "checkbox",
  },
  {
    title: "Start Date",
    id: "startDate",
    type: "date",
    size: "medium",
  },
  {
    title: "End Date",
    id: "endDate",
    type: "date",
    size: "medium",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Online Store",
    id: "isPublishedToOnlineStore",
    type: "checkbox",
  },
  {
    title: "Sale Type",
    id: "saleType",
  },
  {
    title: "Size",
    id: "size",
  },
  {
    title: "Group",
    id: "group.name",
  },
  {
    title: "Quantity",
    id: "quantity",
  },
  {
    title: "Price",
    id: "price",
    prefix: "$",
  },
  {
    title: "Quantity Limit",
    id: "quantityLimit",
  },
  {
    title: "Minimum Price",
    id: "minimumPrice",
    prefix: "$",
  },
  {
    title: "Maximum Price",
    id: "maximumPrice",
    prefix: "$",
  },
  {
    title: "Apply Date Range",
    id: "applyDateRange",
    type: "checkbox",
  },
  {
    title: "Start Date",
    id: "startDate",
    type: "date",
  },
  {
    title: "End Date",
    id: "endDate",
    type: "date",
  },
  {
    title: "Id",
    id: "_id",
  },
];

//Special Pricing Information - Main Section
export const specialPricingInputs = [
  {
    id: "saleType",
    placeholder: "Sale Type",
    type: "select",
    title: "Sale Type",
    label: "label",
    options: Constants.listOfSaleTypes,
    class: "grid-row-two-column",
  },
  {
    id: "isActive",
    type: "checkBox",
    title: "Active",
    placeholder: "Active",
  },
  {
    id: "isPublishedToOnlineStore",
    type: "checkBox",
    title: "Publish to Online Store",
    placeholder: "Publish to Online Store",
  },
  {
    id: "group",
    placeholder: "Group",
    type: "search",
    title: "Group",
    label: "name",
    entity: Entities.GROUP,
    columnHeaders: groupColumnHeaders,
    filterCriteria: groupFilterCriteria,
    isClearable: true,
  },
  {
    id: "supplier",
    placeholder: "Supplier",
    type: "search",
    title: "Supplier",
    label: "name",
    entity: Entities.SUPPLIER,
    columnHeaders: supplierColumnHeaders,
    filterCriteria: supplierFilterCriteria,
    isClearable: true,
  },
  {
    id: "brand",
    placeholder: "Brand",
    type: "search",
    title: "Brand",
    label: "name",
    entity: Entities.BRAND,
    columnHeaders: brandColumnHeaders,
    filterCriteria: brandFilterCriteria,
    isClearable: true,
  },
  {
    id: "size",
    type: "number",
    title: "Size (g)",
    precision: Constants.numericInput.defaultWeightPrecision,
  },
  {
    id: "quantity",
    type: "number",
    title: "Quantity",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "price",
    type: "money",
    title: "Price",
    precision: Constants.numericInput.defaultPrecision,
    prefix: "$",
    class: "grid-row-two-column",
  },
  {
    id: "percentageAmount",
    type: "percentage",
    title: "Discount Percentage",
    precision: 0,
    prefix: "",
    suffix: "%",
  },
  {
    id: "type",
    placeholder: "Special Pricing Type",
    type: "select",
    title: "Special Pricing Type",
    label: "label",
    options: Constants.listOfSpecialPricingTypes,
    class: "grid-row-two-column",
  },
  // {
  //   id: "pricePreTax",
  //   type: "money",
  //   title: "Price Pre Tax",
  //   precision: Constants.numericInput.moneyPrecision,
  //   prefix: "$",
  //   class: "grid-row-two-column",
  // },
  {
    id: "quantityLimit",
    type: "number",
    title: "Quantity Limit",
    precision: Constants.numericInput.defaultPrecision,
    class: "grid-row-two-column",
  },
  {
    title: "Expiring In",
    id: "expiringIn",
    type: "select",
    options: Constants.expiringIn,
  },
  {
    title: "Day of Week",
    id: "dayOfWeek",
    type: "select",
    options: Constants.dayOfWeek,
  },
  {
    id: "minimumPrice",
    type: "money",
    title: "Minimum Price",
    prefix: "$",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "maximumPrice",
    type: "money",
    title: "Maximum Price",
    prefix: "$",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "applyDateRange",
    type: "checkBox",
    title: "Apply Date Range",
    placeholder: "Apply Date Range",
    class: "grid-row-two-column",
  },
  {
    id: "startDate",
    placeholder: "Start Date",
    type: "calendar",
    title: "Start Date",
    showTime: true,
  },
  {
    id: "endDate",
    placeholder: "End Date",
    type: "calendar",
    title: "End Date",
    showTime: true,
  },
];
