import { render, staticRenderFns } from "./PosHeader.vue?vue&type=template&id=a557defa&scoped=true&"
import script from "./PosHeader.vue?vue&type=script&lang=js&"
export * from "./PosHeader.vue?vue&type=script&lang=js&"
import style0 from "./PosHeader.vue?vue&type=style&index=0&id=a557defa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a557defa",
  null
  
)

export default component.exports