<template>
  <div class="pause-ordering">
    <div :class="isPaused ? 'pause-ordering__status-paused' : 'pause-ordering__status-unpaused'">
      <div>{{ isPaused ? "Ordering Paused" : "Ordering NOT Paused" }}</div>
      <div class="pause-ordering__status-unpaused__subtext" v-if="!isPaused">(Pause before counting)</div>
    </div>
    <BaseBtn :label="isPaused ? 'Unpause Orders' : 'Pause Orders'" iconGroup="fa"  :iconName="isPaused ? 'play' : 'pause'"
      @click="toggleShowPause" :colorType=4 />
    <BaseModal v-if="showPause" @toggle-modal="toggleShowPause"
      :title="isPaused ? 'Unpause Ordering' : 'Pause Ordering'" :autoWidth="true">
      <div style="
          padding: 1.5rem;
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          min-width: 500px;
        ">
        <div style="display: flex; flex-direction: column; gap:40px">
          <div>Are you sure you want to {{ isPaused ? "unpause" : "pause" }} ordering?</div>
          <div style="display:flex; gap:10px; justify-content: center;">
            <BaseBtn label="Cancel" iconGroup="fa" iconName="times" @click="toggleShowPause" />
            <BaseBtn :label="isPaused ? 'Unpause' : 'Pause'" iconGroup="fa" :iconName="isPaused ? 'play' : 'pause'"
              :colorType=4 @click="togglePause()" />
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";

export default {
  name: "PauseOrdering",
  data() {
    return {
      showPause: false,
    };
  },
  components: {
    BaseBtn,
    BaseModal,
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
    }),
    isPaused() {
      return this.currentStore.isInventoryCountEnabled == true;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
      toggleOrderPause: "storeModule/toggleOrderPause",
    }),
    toggleShowPause() {
      this.showPause = !this.showPause;
    },
    togglePause() {
      this.currentStore.isInventoryCountEnabled = !this.currentStore.isInventoryCountEnabled;

      const body = {
        storeId: this.currentStore._id,
        isInventoryCountEnabled: this.currentStore.isInventoryCountEnabled,
      }

      this.showPause = false;

      this.toggleOrderPause({
        body,
        positiveCallback: () => {
          this.setMessage({
            text: `${this.isPaused ? "Paused" : "Unpaused"} Ordering`,
            type: "success",
          });
        },
        negativeCallback: () => {
          this.setMessage({
            text: `Failed to ${this.isPaused ? "Pause" : "Unpause"} Ordering`,
            type: "error",
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_pauseOrdering.scss";
</style>
