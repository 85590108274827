<template>
  <div class="specialPricing responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'specialPricings', label: 'SpecialPricing' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity"
        :sort="sort"
        @sortChangeEvent="updateSort"
        :pagination="pagination"
        @paginationChange="updatePagination"
        :initialFilter="initialFilter"
        @initialFilterUpdate="updateInitialFilter"
        :enableAdditionalFilter=true
      />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";
import { mapActions, mapGetters } from "vuex";

import { listPageHeader } from "./inputs";

export default {
  name: "specialPricing",
  data() {
    return {
      Entities,
      entity: Entities.SPECIALPRICING,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  created: function () {
    this.setPageTitle("Special Pricing");
  },
  computed: {
    ...mapGetters({
      sort: "specialPricingModule/sort",
      pagination: "specialPricingModule/pagination",
      initialFilter: "specialPricingModule/initialFilter",
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "specialPricingModule/updateSort",
      updatePagination: "specialPricingModule/updatePagination",
      updateInitialFilter: "specialPricingModule/updateInitialFilter",
    }),
  },
};
</script>
