<template>
  <div class="cash-reconciliations responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'inventoryCounts', label: 'Inventory Counts' },
      ]" :entity="entity">
        <PauseOrdering v-if="hasPermissions"/>
      </PageHeader>
      <SearchTable :key="key" :entity="entity" :sort="sort" @sortChangeEvent="updateSort" :pagination="pagination" :filterButtons="filterButtons" @filterButtonsChange="updateFilterButtons"
        @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter" :initialFilter="initialFilter"
        :enableAdditionalFilter=true @remove-item="archive" />
    </div>
    <BaseModal v-if="showArchive" @toggle-modal="toggleArchive" :title="selectedEntity.isArchived ? 'Unarchive' : 'Archive'" :autoWidth="true">

      <div style="
          padding: 1.5rem;
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        ">
        <div style="display: flex; flex-direction: column; gap:40px">
          <div>Are you sure you want to {{selectedEntity.isArchived ? "unarchive" : "archive"}}?</div>
          <div style="display:flex; gap:10px">
            <BaseBtn  label="Cancel" iconGroup="fa" iconName="times"
              @click="toggleArchive" />
            <BaseBtn :label="selectedEntity.isArchived ? 'Unarchive' : 'Archive'" iconGroup="fa" iconName="archive"
              @click="archiveCount()" />
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import PauseOrdering from "@/components/PauseOrdering/PauseOrdering.vue";
import { isInventoryManager } from '@/lib/rolesHelper'

export default {
  name: "inventoryCounts",
  data() {
    return {
      key: 0,
      listPageHeader,
      entity: Entities.INVENTORYCOUNT,
      showArchive: false,
      selectedEntity: null,
    };
  },
  mounted: function () {
    this.setPageTitle("InventoryCount");
  },
  components: {
    PageHeader,
    SearchTable,
    BaseModal,
    BaseBtn,
    PauseOrdering,
  },
  computed: {
    ...mapGetters({
      getCurrentStore: "storeModule/currentStore",
      sort: "inventoryCountModule/sort",
      pagination: "inventoryCountModule/pagination",
      initialFilter: "inventoryCountModule/initialFilter",
      filterButtons: "inventoryCountModule/filterButtons",
      getUser: 'loginModule/user',
    }),
    hasPermissions() {
      return isInventoryManager(this.getUser, this.getCurrentStore._id);
    }
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
      updateSort: "inventoryCountModule/updateSort",
      updatePagination: "inventoryCountModule/updatePagination",
      updateInitialFilter: "inventoryCountModule/updateInitialFilter",
      updateFilterButtons: "inventoryCountModule/updateFilterButtons",
      toggleArchiveInventoryCount: "inventoryCountModule/toggleArchiveInventoryCount",
    }),
    archive(entity, selectedEntity, nestedId) {
      this.selectedEntity = selectedEntity;
      this.showArchive = true;
    },
    toggleArchive(event) {
      this.showArchive = false;
    },
    archiveCount() {
      this.showArchive = false;
      this.toggleArchiveInventoryCount({
        _id: this.selectedEntity._id,
        isArchived: !this.selectedEntity.isArchived ?? false,
        positiveCallback: () => {
          this.key = this.key + 1;
          this.setMessage({
            text: `Inventory Count ${this.selectedEntity.isArchived ? "Unarchived" : "Archived"}`,
            type: "success"
          });
        }
      })
    }
  }
}
</script>
