<template>
  <div class="table-component-wrapper">
    <div class="bordered">
      <div :class="'table-container' + ' table-full-screen'">

        <div id="table-pagination" class="table__pagination--wrapper">
          <div class="table__pagination--wrapper" style="flex-grow: 10; " v-if="selectable">
            <div style="display: flex; gap: 1rem;">
              <BaseBtn @click="showSelectedItems()" :size="'sm'">
                {{ showSelected ? 'Show Results' : 'Show Selected' }}
              </BaseBtn>
              <BaseBtn @click="selectAllItems()" :size="'sm'" :disabled="showSelected">
                Select All
              </BaseBtn>
              <BaseBtn @click="deselectAllItems()" :size="'sm'" :disabled="showSelected">
                Deselect All
              </BaseBtn>
              <BaseBtn @click="clearSelectedItems()" :size="'sm'" :colorType="1">
                Clear Selected
              </BaseBtn>
            </div>
          </div>
          <div v-if="!selectable" style="flex-grow:10"></div>
          <div v-if="enableCardniality" class="table__pagination__cardinality">
            {{ internalHasForcedItems ? internalData.length : cardinality }} ITEMS
          </div>
          <div v-else></div>
          <div class="table__pagination flex" v-if="pageNumber && !internalHasForcedItems">
            <font-awesome-icon v-if="firstArrowEnabled" :class="'table__pagination__icon ' +
        (isFirstDisabled || searchTableLoadingStatus.isFilterLoading
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-double-left']" @click="emitPageChange('first', isFirstDisabled)" />
            <font-awesome-icon :class="'table__pagination__icon ' +
        (isPrevDisabled || searchTableLoadingStatus.isFilterLoading
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-left']" @click="emitPageChange('prev', isPrevDisabled)" />
            <span class="table__pagination__result-total">{{ currentPageNumber }} of {{ pageNumber }}</span>
            <font-awesome-icon :class="'table__pagination__icon ' +
        (isNextDisabled || searchTableLoadingStatus.isFilterLoading
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-right']" @click="emitPageChange('next', isNextDisabled)" />
            <font-awesome-icon v-if="lastArrowsEnalbled" :class="'table__pagination__icon ' +
        (isLastDisabled || searchTableLoadingStatus.isFilterLoading
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-double-right']" @click="emitPageChange('last', isLastDisabled)" />
          </div>

          <div class="table__pagination flex" v-if="!enableCardniality && !pageNumber && !internalHasForcedItems">
            <font-awesome-icon :class="'table__pagination__icon ' +
        (isPrevDisabled || searchTableLoadingStatus.isFilterLoading
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-left']" @click="emitPageChange('prev', isPrevDisabled)" />
            <span class="table__pagination__result-total">Page {{ currentPageNumber }}</span>
            <font-awesome-icon :class="'table__pagination__icon ' +
        (isNextDisabled || searchTableLoadingStatus.isFilterLoading || rowCount < 20
          ? 'table__pagination__disabled'
          : 'table__pagination__active')
        " :icon="['fas', 'angle-right']" @click="emitPageChange('next', isNextDisabled || rowCount < 20)" />
          </div>
          <div class="table__pagination flex config-dropdown">
            <MultiSelect v-show="showOptions" v-model="optionColumnHeaders" :options="listedColumnHeaders" optionLabel="title" display="chips" placeholder="Toggle Columns" style="max-width: 10rem;" />
            <font-awesome-icon :icon="['fas', 'cog']" @click="() => showOptions = !showOptions" class="config-icon"/>
          </div>
        </div>

        <div class="table-wrapper" :style="tableHeight">
          <div class="table__loading-overlay" v-if="searchTableLoadingStatus.isFilterLoading"></div>
          <table class="table-element" id="table-element">
            <thead>
              <tr class="header" :class="{
        'header--disabled': searchTableLoadingStatus.isFilterLoading,
      }">
                <th v-for="(header, index) in displayColumnHeaders" :key="`table-column-header-${header.id}-${header.title}`"
                  class="header-cell header-cell--button" :class="getStickyClass(index, header, true)"
                  :id="`table-column-header-${index}`"
                  :style="[getStickyStyle(index, header), { width: columnWidths[index] + 'px' }]" @click="() => handleSortChange(header)">
                  <div :class="`table__content table__content--size-${header.size}`" v-if="header.type !== 'button'">
                    {{ header.title }} {{ generateIcon(header) }}
                  </div>
                  <div v-else class="table__header-button"></div>
                  <div class="resizer" style="flex-grow:10;" @mousedown="initResize($event, index)" @click.stop><div class="resizer-icon">|</div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="row" v-for="item in internalData" :key="item._id" :class="{
        'table--loading': searchTableLoadingStatus.isFilterLoading,
      }">
                <td class="cell" :class="getStickyClass(index, header)" v-for="(header, index) in displayColumnHeaders"
                  :key="header.id + '_' + header.title" :style="[getStickyStyle(index, header), { width: columnWidths[index] + 'px' }]">
                  <router-link :to="getRouteToEntity(entity, item)"
                    v-if="header.type === 'button' && header.action === 'navigateToEntity'">
                    <BaseBtn class="table-row-button" :label="header.title"
                      type="plain-primary responsive-column form-height small" data-cy="search-select-button"
                      iconGroup="fas" :iconName="header.iconName" :ariaLabel="header.ariaLabel" :style="{ width: columnWidths[index] + 'px' }"
                      :disabled="isButtonDisabled(header, item)" :class="{ 'table-row-button--big': bigIcons }" />
                  </router-link>
                  <BaseBtn class="table-row-button" v-else-if="header.type === 'button'" :label="header.title" :style="{ width: columnWidths[index] + 'px' }"
                    type="plain-primary responsive-column form-height small" data-cy="search-select-button" @click="() => handleButtonOnClick(item, header.action, header)
        " iconGroup="fas" :iconName="header.iconName" :ariaLabel="header.ariaLabel"
                    :disabled="isButtonDisabled(header, item)" :class="{ 'table-row-button--big': bigIcons }" />
                  <div v-else-if="header.link" :class="`table__content table__content--size-${header.size}`" :style="{ width: columnWidths[index] + 'px' }">
                    <a v-if="getItemValue(item, header) !== '-'" class="internal-link" target="_blank"
                      :href="header.url + getNestedValue(item, header.urlId)">
                      {{ getItemValue(item, header) }}
                    </a>
                    <div v-else>-</div>
                  </div>
                  <div v-else-if="header.type === 'checkbox' && header.action === 'selectItem'"
                    :class="`table__content table__content--size-${header.size}`" style="width: 1.5rem;" :style="{ width: columnWidths[index] + 'px' }">
                    <CheckBox :value="item.isSelected" @click="selectItem(item)" />
                  </div>
                  <div v-else-if="header.type === 'checkbox'"
                    :class="`table__content table__content--size-${header.size}`" :style="{ width: columnWidths[index] + 'px' }">
                    <check-mark class="table-row-button" :toggleVariable="getItemValue(item, header) == 'true'" />
                  </div>
                  <div v-else-if="header.type === 'label'"
                    :class="`table__content table__content--size-${header.size}`" :style="{ width: columnWidths[index] + 'px' }">
                    <div class="table__pagination__label">
                      <div class="table__pagination__label-content">
                        <font-awesome-icon v-if="header.iconGroup && header.iconName"
                          :icon="[header.iconGroup, header.iconName]" />
                        {{ getItemValue(item, header) }}
                      </div>
                    </div>
                  </div>
                  <div v-else :class="`table__content table__content--size-${header.size}`" :style="{ width: columnWidths[index] + 'px' }">
                    <span>{{ getItemValue(item, header) || "-" }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Constants from "@/resources/Constants";
import { mapGetters } from "vuex";
import { routeToEntity, getRouteToEntity } from "@/utils/routeToEntity";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";
import MultiSelect from "primevue/multiselect";

library.add(faUserSecret);
library.add(faUserSecret);

export default {
  name: "Table",
  data() {
    return {
      Constants: Constants,
      moment: moment,
      windowWidth: window.innerWidth,
      sort_: {},
      internalData: [],
      internalSelectedItems: [],
      getRouteToEntity,
      isSelected: true,
      showSelected: false,
      internalHasForcedItems: false,
      columnWidths: {},
      isResizing: false,
      currentColumn: null,
      startX: 0,
      startWidth: 0,
      displayColumnHeaders: [],
      optionColumnHeaders: [],
      showOptions: false,
    };
  },
  props: {
    columnHeaders: {
      type: Array,
    },
    loadingStatus: {
      type: Boolean,
    },
    data: {
      type: [Object, Array],
    },
    entity: {
      type: String,
    },
    id: {
      type: String,
    },
    nestedId: {
      type: String,
    },
    currentPageNumber: {
      type: Number,
    },
    pageNumber: {
      type: Number,
    },
    cardinality: {
      type: Number,
    },
    height: {
      type: Number,
    },
    hasForcedItems: {
      type: Boolean,
      default: false,
    },
    bigIcons: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    searchTableLoadingStatus: {
      type: Object,
      default: () => ({}),
    },
    firstArrowEnabled: {
      type: Boolean,
      default: true,
    },
    lastArrowsEnalbled: {
      type: Boolean,
      default: true,
    },
    enableCardniality: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    clearSelected: {
      type: Number,
    },
  },
  components: {
    FontAwesomeIcon,
    BaseBtn,
    CheckBox,
    MultiSelect,
  },
  created() {
    this.sort_ = this.sort;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    this.displayColumnHeaders = this.columnHeaders;
    this.loadColumnHeaders();
    this.setColumnHeaders();

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    this.internalHasForcedItems = this.hasForcedItems;
    this.internalData = this.selectable ? this.data.map(d => ({ ...d, isSelected: false })) : this.data;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  },
  watch: {
    columnHeaders: function (data) {
      this.displayColumnHeaders = data;
      this.loadColumnHeaders();
      this.setColumnHeaders();
    },
    urlId: function () {
      if (this.urlId && this.urlId !== "NEW") {
        this.$forceUpdate();
      }
    },
    data: function () {
      if (!this.selectable) this.internalData = this.data;
      else {
        this.internalData = this.data.map(d => ({ ...d, isSelected: this.internalSelectedItems.find((i) => i._id === d._id) }));
      }
    },
    selectedItems: function () {
      this.internalSelectedItems = this.selectedItems;
    },
    clearSelected: function () {
      this.clearSelectedItems();
    },
    optionColumnHeaders: function () {
      this.setColumnHeaders();
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      getUser: 'loginModule/user',
      currentStore: "storeModule/currentStore",
    }),
    urlId: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    isFirstDisabled() {
      return this.currentPageNumber === 1 || this.cardinality === 0;
    },
    isNextDisabled() {
      return (
        this.pageNumber === 1 ||
        this.currentPageNumber === this.pageNumber ||
        this.cardinality === 0
      );
    },
    isPrevDisabled() {
      return this.currentPageNumber === 1 || this.cardinality === 0;
    },
    isLastDisabled() {
      return (
        this.pageNumber === 1 ||
        this.currentPageNumber === this.pageNumber ||
        this.cardinality === 0
      );
    },
    listedColumnHeaders: function () {
      return this.columnHeaders.filter((header) => header.title != undefined);
    },
    tableHeight() {
      return this.height ? `height: calc(${this.height}px - 8vh);` : "auto";
    },
    tableWidth() {
      return document.getElementById("table-element")?.offsetWidth
    },
    isMobile() {
      return this.windowWidth <= 500;
    },
    rowCount() {
      return this.internalData?.length || 0
    },
    columnConfigKey() {
      return `${this.$route.name}-${this.entity}-column-config`;
    },
  },
  methods: {
    setColumnHeaders(){
      this.displayColumnHeaders = this.columnHeaders.filter((header) => header.title == undefined || this.optionColumnHeaders.map(c => c.id).includes(header.id));
      this.columnWidths = [];
      const columnWidth = (this.tableWidth / this.displayColumnHeaders.length ?? 10);

      this.displayColumnHeaders.forEach((header, index) => {
        let width = Math.max(columnWidth, 100);
        if(this.isMobile || index === this.displayColumnHeaders.length - 1) width = 'auto';
        else if (header.size === "small") width = 50;
        else if (header.size === "medium") width = 120;
        else if (header.size === "large") width = 200;
        else if (header.size === "xlarge") width = 250;
        else if (header.size === "batch") width = 145;
        else if (header.size === "auto") width = 'auto';
        else if (['button', 'checkbox'].includes(header.type)) width = 53;
        this.$set(this.columnWidths, index, width);
      });

      this.saveColumnHeaders();
    },
    loadColumnHeaders(){
      const columnConfig = localStorage.getItem(this.columnConfigKey);
      if (columnConfig) {
        this.optionColumnHeaders = JSON.parse(columnConfig);
      }
      else {
        this.optionColumnHeaders = this.columnHeaders.filter(c => c.title != undefined);
      }
    },
    saveColumnHeaders(){
      localStorage.setItem(this.columnConfigKey, JSON.stringify(this.optionColumnHeaders.filter(c => c.title != undefined)));
    },
    selectItem(item) {
      item.isSelected = !item.isSelected;
      if (this.internalSelectedItems.find((i) => i._id === item._id)) {
        this.internalSelectedItems = this.internalSelectedItems.filter((i) => i._id !== item._id);
      } else {
        this.internalSelectedItems.push({ _id: item._id, item });
      }
      this.$emit('itemsSelected', this.internalSelectedItems.map((i) => i.item));
    },
    showSelectedItems() {
      this.internalHasForcedItems = !this.internalHasForcedItems
      this.showSelected = !this.showSelected
      if (!this.showSelected) this.internalData = this.data.map(d => ({ ...d, isSelected: this.internalSelectedItems.some((i) => i._id === d._id) }));
      else this.internalData = this.internalSelectedItems.map(d => d.item)
    },
    clearSelectedItems() {
      this.showSelected = false
      this.internalHasForcedItems = false
      this.internalSelectedItems = []
      this.internalData = this.data.map(d => ({ ...d, isSelected: this.internalSelectedItems.some((i) => i._id === d._id) }));
      this.$emit('itemsSelected', [])
    },
    selectAllItems() {
      const items = this.data
      .filter(d => !this.internalSelectedItems.find((i) => i._id === d._id))
      .map(d => ({ _id: d._id, item: {...d, isSelected: true }}));
      this.internalSelectedItems.push(...items);
      this.internalData = this.data.map(d => ({ ...d, isSelected: this.internalSelectedItems.some((i) => i._id === d._id) }));

      const selectedItems = this.internalSelectedItems.map((i) => i.item);
      this.$emit('itemsSelected', selectedItems)
    },
    deselectAllItems() {
      this.internalSelectedItems = this.internalSelectedItems.filter(d => !this.data.find(i => i._id === d._id));
      this.internalData = this.data.map(d => ({ ...d, isSelected: this.internalSelectedItems.find((i) => i._id === d._id) }));
      const selectedItems = this.internalSelectedItems.map((i) => i.item);
      this.$emit('itemsSelected', selectedItems)
    },
    initResize(event, index) {
      this.isResizing = true;
      this.currentColumn = index;
      this.startX = event.clientX;
      if(this.columnWidths[index] === 'auto')
        this.startWidth = document.getElementById(`table-column-header-${index}`).offsetWidth;
      else
        this.startWidth = this.columnWidths[index];
    },
    onMouseMove(event) {
      if (!this.isResizing) return;
      const dx = event.clientX - this.startX;
      this.$set(this.columnWidths, this.currentColumn, Math.max(this.startWidth + dx, 50));
    },
    onMouseUp() {
      if (this.isResizing) {
        setTimeout(() => {
          this.isResizing = false;
          this.currentColumn = null;
        }, 100);
      }
    },
    handleButtonOnClick: function (item, action, header) {
      if (action === "navigateToEntity") {
        routeToEntity(this.entity, item);
      } else if (action === "selectItem") {
        this.$emit("select-item", this.id, item, this.nestedId);
      } else if (action === "removeItem")
        return this.$emit("remove-item", this.id, item, this.nestedId);
      else {
        this.$parent?.handleButtonClicked(action, this.id, item);
      }
      this.$emit("toggle-modal", item);
    },
    getNestedValue(item, id) {
      if (id.includes(".")) {
        const keys = id.split(".");
        let result = item;
        keys.forEach((key) => {
          if (result) result = result[key];
        });
        return result;
      } else return item[id];
    },
    generateIcon(header) {
      if (this.sort_[header.id]) {
        switch (this.sort_[header.id]) {
          case 1:
            return "↑";

          case -1:
            return "↓";
        }
      } else {
        return "";
      }
    },
    handleSortChange(header) {
      if (
        this.isResizing ||
        this.showSelected ||
        header.type == "button" ||
        this.searchTableLoadingStatus.isFilterLoading ||
        header.isSortable === false
      ) {
        return;
      }

      const newSortingColumn = { [header.id]: 1 }
      const existingSortingColumn = this.sort_[header.id]

      if (existingSortingColumn) {
        delete this.sort_[header.id];
        if (existingSortingColumn == 1) {
          newSortingColumn[header.id] = -1;
          this.sort_ = { ...newSortingColumn, ...this.sort_ };
        }
      } else {
        this.sort_ = { ...newSortingColumn, ...this.sort_ };
      }

      this.$emit("sortChange", this.sort_);
    },
    getItemValue(item, header, alternativeId) {
      const id = header.id;

      if (!id && header.dynamicValue) {
        return header.dynamicValue(item);
      }
      let result = this.getNestedValue(item, id);

      if (!result && alternativeId)
        result = this.getNestedValue(item, alternativeId);

      if (result === 0 && header.title != "Day of Week") return (header.prefix || "") + "0";
      //if (result === 0) return (header.prefix || "") + "0";
      else if (header.type === "select") {
        const selectItem = header.options.find((o) => o.code === result);
        if (typeof selectItem === "object") return selectItem.label;
        return selectItem;
      } else if (!result) return "-";
      else if (header.type === "date") {
        const dateFormat =
          header.dateFormat || Constants.dates.defaultDateFormat;
        return moment(result).format(dateFormat);
      } else if (header.type === "date-extended") {
        return moment(result).format(Constants.dates.extendedDateFormat);
      } else if (header.type === "timeOnly") {
        return moment(result).format(Constants.dates.timeOnly);
      } else if (header.type === "money") {
        return Utilities.formatPrice(result);
      } else if (header.type === "name") {
        return Utilities.composeNameAndLastName(result);
      } else return (header.prefix || "") + result;
    },
    formatDate(value) {
      if (!value || value == "-") return null;
      return Utilities.formatDate(value);
    },
    getStickyStyle(index, header) {
      if (this.isMobile && index !== 0) return "";
      else if (header.position === "sticky" || index === 0)
        return `left: ` + index * 53 + "px";
      else return "";
    },
    getStickyClass(index, header, isHeader) {
      if (this.isMobile && index !== 0) return "";
      else if (header.position === "sticky" || index === 0) {
        if (isHeader) return "sticky-header sticky";
        else return "sticky";
      } else return "";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    isButtonDisabled(header, item) {
      if (header.disabled) return header.disabled(item, this.getUser, this.currentStore);
      else return false;
    },
    emitPageChange(action, disabledMode) {
      if (!this.searchTableLoadingStatus.isFilterLoading) {
        this.$emit("get-next-entities", action, disabledMode);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.table-container {
  position: relative;
}

.header {
  cursor: pointer;

  &--disabled {
    transition: 0.3;
    color: gray;
    cursor: not-allowed;
  }
}

.header-cell {
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-cell:hover > * {
  visibility: visible;
}

.resizer {
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  user-select: none;
  flex-grow: 10;
  padding-top: .25rem;
  visibility: hidden;
}

.header-cell--button {
  width: 20px !important;
}

.sticky-header {
  z-index: 12;
  min-width: 53px;
}

.table-wrapper {
  overflow: auto;
  position: relative;
}

.table-element .cell {
  background-color: #1b1b1d;
  margin-right: 0;
  padding-right: 0;
}

.light .table-element .cell {
  background-color: #eaebef;
  width: 100%;
}

tr {
  vertical-align: top;
}

.loader {
  display: flex;
  justify-content: center;
}

.sticky {
  position: sticky;
  left: 0;
}

.table-row-button {
  padding: 5px;

  &--big {
    padding: 10px;
  }
}

.table {
  &--loading {
    filter: blur(4px);
    transition: 0.3;
  }

  &__content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;

    &>a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover>a {
      overflow: visible;
      position: absolute;
      width: auto;
      padding-right: 2rem;
    }

    &>span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover>span {
      overflow: visible;
      position: absolute;
      width: auto;
      padding-right: 1rem;
    }

    &--size-batch {
      width: 145px;
    }

    &--size-medium {
      width: 100px;
    }

    &--size-large {
      width: 200px;
    }
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //z-index: 10;
    height: 100%;
  }

  &__default-message {
    text-align: center;
    margin-top: 10px;
  }

  &__header-button {
    z-index: 20;
  }

  &__pagination {
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 25px;

    &--wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 5vh;
    }

    &__cardinality {
      font-size: 14px;
      padding-left: 0.5em;
      font-weight: bold;
    }

    &__result-total {
      font-size: 14px;
    }

    &__icon {
      margin: 10px;
      cursor: pointer;
    }

    &__label {
      display: inline-block;
    }

    &__label-content {
      padding: 2px 10px;
      text-transform: uppercase;
      font-size: $text-size-1;
      border-radius: $border-radius-0;
      display: flex;
      gap: 5px;
      align-items: center;
      border: 1px solid $dark-accent-color;
    }

    &__active {
      &:hover {
        color: $dark-accent-color;
      }
    }

    &__disabled {
      color: gray;
      cursor: not-allowed;
      transition: 0.3;

      &:hover {
        color: "black" !important;
      }
    }
  }
}

.config-dropdown {
  z-index: 100;
  padding: 0 .5rem;
  transition: all 0.3s;
}

.config-icon {
  padding-left: .5rem;
  &:hover {
    cursor: pointer;
  }
}

.light .table {
  &__pagination {
    &__active {
      &:hover {
        color: $light-accent-color;
      }
    }
  }
}
</style>
