// Page Header
export const pageHeader = {
  title: "MSO Supplier",
  isEditorView: true,
  cancelPath: "msoSuppliers",
  addPath: "msoSupplierEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "MSO Suppliers",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "msoSupplierEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    numCharsToSearch: 1,
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  {
    label: "Accounting Code",
    code: "nameCode",
    numCharsToSearch: 1,
    field: { type: "input", label: "nameCode" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "City",
    id: "city",
    size: "large",
  },
  {
    title: "State",
    id: "state",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Accounting Code",
    id: "nameCode",
    size: "medium",
  },
  {
    title: "Store",
    id: "store",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Accounting Code",
    id: "nameCode",
  },
  {
    title: "Store",
    id: "store",
  },
];

export const msoSupplierInfoInputs = [
  {
    id: "name",
    placeholder: "Supplier Name",
    type: "input",
    title: "Supplier Name",
    focus: true,
  },
  {
    id: "nameCode",
    placeholder: "Accounting Code",
    type: "input",
    title: "Accounting Code",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedMsoSupplier = {
  _id: "NEW",
  name: "",
  nameCode: "",
  isActive: true,
  isDeleted: false,
  isArchived: false,
  notes: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};
