<template>
  <div class="header">
    <div class="header__left">
      <div class="back" @click="backHandler">
        <i class="fas fa-arrow-left"></i>
        <span>Back</span>
      </div>
      <div class="overview" v-if="!inventoryCount.isClosed">
        <div class="overview__row">
          <p class="overview__row__title">Room:</p>
          <p class="overview__row__value">{{ inventoryCount.compareTo.name }}</p>
        </div>
        <div v-if="inventoryCount.compareTo.productType" class="overview__row">
          <p class="overview__row__title">Product:</p>
          <p class="overview__row__value">{{ inventoryCount.compareTo.productType }}</p>
        </div>
        <div class="overview__row" v-canAccess="managementRoles">
          <p class="overview__row__title">Status:</p>
          <div class="overview__row__value">
            <p>{{ status }}</p>
            <p class="employees"> {{ employeesNames }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="header__center">
      <Scanner v-if="!doneCounting && !inventoryCount.isClosed" v-on="$listeners" ref="scanner" />
      <Info v-if="inventoryCount.isClosed" :createdBy="inventoryCount.createdBy" :createdAt="inventoryCount.createdAt"
        :closedAt="inventoryCount.closedAt" :room="inventoryCount.compareTo.name" :status="status" />
    </div>
    <div class="actions">
      <div class="actions-inline">
        <BaseBtn v-if="hasItems && !doneCounting" label="Done Counting" iconGroup="fas" iconName="clipboard-list"
          @click="compareHandler" :isLoading="isLoading" :disabled="isLoading" />
        <!-- <BaseBtn v-if="hasItems && !doneConfirm && doneCounting" label="Confirm" iconGroup="fas" iconName="clipboard-list"
          @click="compareHandler" /> -->
        <BaseBtn v-if="hasItems && doneCounting && !inventoryCount.isClosed" label="Print Off Counts" :colorType="0" iconGroup="fas" iconName="file-export"
          @click="detailPrintHandler" />
        <BaseBtn v-canAccess="managementRoles" v-if="canBeClosed && canCheckMissingBatches" label="Check missing batches"
          iconGroup="fas" iconName="clipboard-list" @click="checkMissignBathesHandler" :isLoading="isLoading"
          :disabled="isLoading" />
        <BaseBtn v-canAccess="managementRoles" v-else-if="canBeClosed" label="Close Report" iconGroup="fas"
          iconName="clipboard-list" @click="closeReportHandler" />
        <BaseBtn v-canAccess="managementRoles" :isLoading="isLoading" v-if="hasItems && doneCounting && !inventoryCount.isClosed"  label="Recalculate" iconGroup="fas" @click="recalculateHandler"
          iconName="sync"/>
      </div>
      <div v-canAccess="managementRoles" v-if="!inventoryCount.isClosed && hasItems">
        <CheckBox sideLabel="Review/Edit" :value="viewAll" @click="$emit('onViewAll')" />
      </div>
      <BaseBtn v-if="inventoryCount.isClosed" label="Export" :colorType="0" iconGroup="fas" iconName="file-export"
        @click="exportHandler" />
    </div>
  </div>
</template>

<script>
import ReportHelper from "@/lib/report/index.js";
import moment from "moment";
import Scanner from '../Scanner/Scanner.vue';
import BaseBtn from "@/components/Base/Button/Button.vue";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";
import {
  managementRoles,
} from "@/constants/roles";
import { mapGetters, mapActions } from 'vuex';
import Info from '../Info/Info.vue';


export default {
  props: {
    inventoryCount: {
      type: Object
    },
    viewAll: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
    },
    canBeClosed: {
      type: Boolean,
      default: false
    },
    canCheckMissingBatches: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      managementRoles,
    }
  },
  components: {
    Scanner,
    BaseBtn,
    CheckBox,
    Info
  },
  computed: {
    ...mapGetters({
      getUser: 'loginModule/user',
      loadingStatus: 'inventoryCountModule/loadingStatus'
    }),
    isLoading() {
      return this.loadingStatus.checkMissingBatches || this.loadingStatus.compareInventoryCount || this.loadingStatus.recalculateCompareInventoryCount
    },
    userId() {
      return this.getUser._id;
    },
    hasItems() {
      let employees = this.inventoryCount.employees;
      const employee = employees.find(e => e.employee._id == this.userId);
      if (!employee) {
        return false;
      }
      if (!employee.items || employee.items.length == 0) return false;
      return true;
    },
    doneCounting() {
      let employees = this.inventoryCount.employees;
      const employee = employees.find(e => e.employee._id == this.userId);
      if (!employee) {
        return false;
      }
      return employee.countCompleted
    },
    doneConfirm() {
      let employees = this.inventoryCount.employees;
      const employee = employees.find(e => e.employee._id == this.userId);
      if (!employee) {
        return false;
      }
      return employee.countConfirmed
    },
    employees() {
      return this.inventoryCount.employees;
    },
    hasEmployees() {
      return this.employees.length > 0
    },
    employeesNames() {
      const employees = this.inventoryCount.employees.filter(e => e.countConfirmed == false);
      const names = [];
      employees.forEach(e => names.push(e.employee.name));
      if (names.length == 0) return ''
      return `(${names.join(' - ')})`
    }
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      compareInventoryCount: 'inventoryCountModule/compareInventoryCount',
      confirmInventoryCount: 'inventoryCountModule/confirmInventoryCount',
      closeInventoryCount: 'inventoryCountModule/closeInventoryCount',
      checkMissingBatches: 'inventoryCountModule/checkMissingBatches',
      recalculateCompareInventoryCount: 'inventoryCountModule/recalculateCompareInventoryCount'
    }),
    recalculateHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: 'Info',
        message: "Are you sure you wish to recalculate the differences?",
        positiveCallback: () => {
          this.recalculateCompareInventoryCount(this.userId)
        },
      });
    },
    setCounter() {
      this.$refs.scanner.setCursor()
    },
    backHandler() {
      this.$router.push({ name: 'inventoryCounts' })
    },
    exportHandler() {
      const closedAt = moment(new Date(this.inventoryCount.closedAt)).format('MM-DD-YYYY');
      const comparedTo = this.inventoryCount.compareTo.name;

      const fileName = `Inventory Count - ${comparedTo} - ${closedAt}`
      const data = this.formatData()
      ReportHelper.exportRecordsToExcel(data, fileName);
    },
    detailPrintHandler() {
      window.open(`../reports/inventory/inventoryCountFeatureOff?inventoryCountId=${this.inventoryCount._id}`, "_blank").focus();
    },
    formatData() {
      const items = this.items;
      const objs = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const hand = this.handQuantity(item)
        const expected = this.expectedCount(item)
        const newObj = {
          'Batch': "'"+item.batchId,
          'Group': item.externalFields.group.name,
          'Supplier': item.externalFields.supplier.name,
          'Product': item.externalFields.product.name,
          'Grams': item.externalFields.product.cannabisGramsUnit || '-',
          'Count': hand,
          'Employee': this.employeesText(item),
          'Expected': expected,
          'Difference': hand - expected
        }
        objs.push(newObj)
      }
      return objs
    },
    expectedCount(item) {
      const employees = item.employees;
      let currentValue = 0;
      employees.forEach(e => currentValue += e.currentValue || 0);
      return currentValue
    },
    employeesText(item) {
      const employees = item.employees;
      let list = []
      employees.forEach(e => list.push(this.getEmployeeText(e)))
      return list.join('</br>')
    },
    getEmployeeText(employee) {
      const text = [`${employee.name}`]
      text.push(`(${employee.handQuantity})`)
      return text.join(' ')
    },
    handQuantity(item) {
      const employees = item.employees;
      let handQuantity = 0;
      employees.forEach(e => handQuantity += e.handQuantity);
      return handQuantity
    },
    compareHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: 'Info',
        message: "Are you sure that you are finished counting? You will NOT be able to update your batch counts after you run this task. Additionally, you will not be able to add new batches.",
        positiveCallback: () => {
          this.compareInventoryCountHandler()
        },
      });
    },
    confirmHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: 'Info',
        message: "Are you sure that you wish to confirm your inventory count? This action cannot be reversed.",
        positiveCallback: () => {
          this.confirmInventoryCount()
        },
      });
    },
    compareInventoryCountHandler() {
      this.compareInventoryCount(this.userId)
    },
    closeReportHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: 'Info',
        message: "Are you sure that you wish to close the inventory count? After this closing, no more changes may be made.<br><br> <strong>Be sure to Unpause Ordering once completed.</strong>",
        positiveCallback: () => {
          this.closeInventoryCount()
        },
      });
    },
    checkMissignBathesHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: 'Info',
        message: "Are you sure that you would like DF to check for missing batches?",
        positiveCallback: () => {
          this.checkMissingBatches()
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import './_header';
</style>
