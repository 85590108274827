const Apis = {
  customerId: process.env.VUE_APP_CUSTOMER_ID,
  storeId: process.env.VUE_APP_STORE_ID,
  backendBaseUrl: process.env.VUE_APP_API_URL || "http://localhost:8081/api/",
  refreshToken: "employee/refreshToken",
  login: "employee/signIn",
  getProducts: "product/getProducts",
  getProductById: "product/getProductById",
  saveProduct: "product/saveProduct",
  deleteProduct: "product/deleteProduct",
  getRooms: "room/getRooms",
  filterRooms: "room/filter",
  getRoomById: "room/getRoomById",
  saveRoom: "room/saveRoom",
  deleteRoom: "room/deleteRoom",
  getSpecialPricings: "specialPricing/getSpecialPricings",
  getSpecialPricingById: "specialPricing/getSpecialPricingById",
  saveSpecialPricing: "specialPricing/saveSpecialPricing",
  deleteSpecialPricing: "specialPricing/deleteSpecialPricing",
  getGroups: "group/getGroups",
  getGroupById: "group/getGroupById",
  saveGroup: "group/saveGroup",
  deleteGroup: "group/deleteGroup",
  getChartOfAccounts: "chartOfAccount/getChartOfAccounts",
  getChartOfAccountById: "chartOfAccount/getChartOfAccountById",
  saveChartOfAccount: "chartOfAccount/saveChartOfAccount",
  deleteChartOfAccount: "chartOfAccount/deleteChartOfAccount",
  getMsoChartOfAccounts: "msoChartOfAccount/getMsoChartOfAccounts",
  getMsoChartOfAccountById: "msoChartOfAccount/getMsoChartOfAccountById",
  saveMsoChartOfAccount: "msoChartOfAccount/saveMsoChartOfAccount",
  deleteMsoChartOfAccount: "msoChartOfAccount/deleteMsoChartOfAccount",

  getCreditMemos: "creditMemo/getCreditMemos",
  getCreditMemoById: "creditMemo/getCreditMemoById",

  getCoupons: "coupon/getCoupons",
  getCouponById: "coupon/getCouponById",

  getMsoCoupons: "msoCoupon/getMsoCoupons",
  getMsoCouponById: "msoCoupon/getMsoCouponById",

  getPayments: "payment/getPayments",
  getPaymentById: "payment/getPaymentById",

  getTaxSetups: "taxSetup/getTaxSetups",
  getTaxSetupById: "taxSetup/getTaxSetupById",

  flights: {
    get: "flight/getFlights",
    getById: "flight/getFlightById",
  },
  timeSheets: {
    get: "timeSheet/getTimeSheets",
    getById: "timeSheet/getTimeSheetById",
  },
  getJournals: "item/getJournals",
  getJournalById: "Journal/getJournalById",
  getJournalTotals: "Journal/getTotals",
  postManualJournals: "Journal/postManualJournals",

  getMsoGroups: "msoGroup/getMsoGroups",
  getMsoGroupById: "msoGroup/getMsoGroupById",
  getMsoSuppliers: "msoSupplier/getMsoSuppliers",
  getMsoSupplierById: "msoSupplier/getMsoSupplierById",
  getMsoProducts: "msoProduct/getMsoProducts",
  getMsoProductById: "msoProduct/getMsoProductById",
  getOrganizationGroupById: "organizationGroup/getOrganizationGroupById",
  saveOrganizationGroup: "organizationGroup/saveOrganizationGroup",
  deleteOrganizationGroup: "organizationGroup/deleteOrganizationGroup",
  getSuppliers: "supplier/getSuppliers",
  getSupplierById: "supplier/getSupplierById",
  saveSupplier: "supplier/saveSupplier",
  deleteSupplier: "supplier/deleteSupplier",
  getSupplierAgents: "supplierAgent/getSupplierAgents",
  getSupplierAgentById: "supplierAgent/getSupplierAgentById",
  saveSupplierAgent: "supplierAgent/saveSupplierAgent",
  deleteSupplierAgent: "supplierAgent/deleteSupplierAgent",
  saveOrganization: "organization/saveOrganization",
  getOrganizaitonById: "organization/getOrganizationById",
  getStores: "store/getStores",
  getStoreById: "store/getStoreById",
  getUserStores: "store/getUserStores",
  saveStore: "store/saveStore",
  toggleOrderPause: "store/toggleOrderPause",
  deleteStore: "store/deleteStore",
  getEmployees: "employee/getEmployees",
  getEmployeeById: "employee/getEmployeeById",
  getEmployeeByPin: "employee/getEmployeeByPin",
  saveEmployee: "employee/saveEmployee",
  deleteEmployee: "employee/deleteEmployee",
  undeleteEmployee: "employee/undeleteEmployee",
  updateEmployeePassword: "employee/updateEmployeePassword",
  changeStore: "employee/changeStore",
  getCustomers: "customer/getCustomers",
  getCustomerById: "customer/getCustomerById",
  getCardExpiration: "customer/getCardExpiration",
  saveCustomer: "customer/saveCustomer",
  createCustomer: "customer/createCustomer",
  deleteCustomer: "customer/deleteCustomer",
  clearCustomerPoints: "customer/clearCustomerPoints",
  addCustomerPoints: "customer/addCustomerPoints",
  updateCashReconciliationUserCashCount:
    "cashReconciliation/updateCashReconciliationUserCashCount",
  findCustomer: "customer/filter",
  customerItemHistory: "customer/itemHistory",
  customerSpendingInformation: "report/getCustomerSpendingInformation",
  customerLocationInformation: "report/getCustomerLocationInformation",
  getCustomerOrdersByDay: "report/getCustomerOrdersByDay",
  saveCustomerNote: "pos/saveCustomerNote",
  getPurchaseOrders: "purchaseOrder/getPurchaseOrders",
  getPurchaseOrderById: "purchaseOrder/getPurchaseOrderById",
  savePurchaseOrder: "purchaseOrder/savePurchaseOrder",
  reversePurchaseOrder: "purchaseOrder/reversePurchaseOrder",
  deletePurchaseOrder: "purchaseOrder/deletePurchaseOrder",
  undeletePurchaseOrder: "purchaseOrder/undeletePurchaseOrder",
  getManifestById: "state/getManifestById",
  getChemicalComposition: "state/getChemicalComposition",
  getStateInventory: "state/getStateInventory",
  checkCannabisLimit: "state/checkCannabisLimit",
  checkCredentials: "state/checkCredentials",
  getItems: "item/getItems",
  getItemById: "item/getItemById",
  saveItem: "item/saveItem",
  move: "item/move",
  getItemHierarchy: "pos/getItemHierarchy",
  getOrders: "order/getOrders",
  saveOrder: "pos/saveOrder",
  updateOrder: "pos/updateOrder",
  processOrder: "pos/processOrder",
  postOrder: "pos/postOrder",
  archiveOrder: "order/archive",
  deleteOrder: "order/deleteOrder",
  savePayment: "order/savePayment",
  archivePayment: "order/archivePayment",
  archiveAllPayments: "order/archiveAllPayments",
  reverseOrder: "order/reverseOrder",
  getOrderById: "pos/getOrderById",
  addRemoveItemToOrder: "pos/addRemoveItemToOrder",
  addItemToOrderByBarcode: "pos/addItemToOrderByBarcode",
  getCustomer: "pos/getCustomer",
  saveQueueCustomer: "queue/saveQueueCustomer",
  deleteQueueCustomer: "queue/deleteQueueCustomer",
  getQueue: "queue/getQueue",
  getQueueCustomerById: "queue/getQueueCustomerById",
  getReturns: "return/getReturns",
  getReturnById: "return/getReturnById",
  saveReturn: "return/saveReturn",
  deleteReturn: "return/deleteReturn",
  getAdjustments: "adjustment/getAdjustments",
  getAdjustmentById: "adjustment/getAdjustmentById",
  saveAdjustment: "adjustment/saveAdjustment",
  deleteAdjustment: "adjustment/deleteAdjustment",
  getDisposals: "disposal/getDisposals",
  getDisposalById: "disposal/getDisposalById",
  saveDisposal: "disposal/saveDisposal",
  deleteDisposal: "disposal/deleteDisposal",
  getOnlineStoreGroups: "onlineStoreGroup/getOnlineStoreGroups",
  getOnlineStoreGroupById: "onlineStoreGroup/getOnlineStoreGroupById",
  saveOnlineStoreGroup: "onlineStoreGroup/saveOnlineStoreGroup",
  deleteOnlineStoreGroup: "onlineStoreGroup/deleteOnlineStoreGroup",
  getTodaysMenu: "item/getGroupedItems",
  getAdjacent: "getAdjacent",
  getCardinality: "getCardinality",
  getAuditFiles: "audit/getAuditFiles",
  getAuditsByProductId: "audit/getAuditsByProductId",

  menu:{
    setMSOProductPriority: "menu/setMSOProductPriority",
    setMSOSupplierPriority: "menu/setMSOSupplierPriority",
    setProductPriority: "menu/setProductPriority",
    setSupplierPriority: "menu/setSupplierPriority",
  },
  group: {
    updateOrdering: "group/updateOrdering",
  },
  creditMemo: {
    save: "creditMemo/saveCreditMemo",
    getById: "creditMemo/getCreditMemoById",
    delete: "creditMemo/deleteCreditMemo",
    postToJournal: "creditMemo/postToJournal",
    reversePostToJournal: "creditMemo/reversePostToJournal",
    createCCP: "creditMemo/createCCP",
    getValidCreditMemoPerCustomerIdAndDate:
      "creditMemo/getValidCreditMemoPerCustomerIdAndDate",
    printCreditMemo: "creditMemo/printCreditMemo",
  },
  integrations: {
    quickbooksHandleAuthCallback: 'store/quickbooksHandleAuthCallback',
    quickbooksAuthUrl: 'store/quickbooksAuthUrl',
    quickbooksAuthDisconnect: 'store/quickbooksAuthDisconnect'
  },
  product: {
    maintenance: {
      duplicateProductWithNewSupplier:
        "product/maintenance/duplicateProductWithNewSupplier",
      changeProductCode: "product/maintenance/changeProductOnPurchaseOrder",
      mergeProductCodes: "product/maintenance/mergeProductCodes",
      changeGroupCode: "product/maintenance/changeGroupCode",
    },
  },
  taxSetup: {
    save: "taxSetup/saveTaxSetup",
    getAll: "taxSetup/getTaxSetups",
    partialSave: "taxSetup/partialSave",
    getAllActive: "taxSetup/getAllActive",
    filterTaxesForTest: "taxSetup/filterTaxesForTest",
  },
  onlineStoreGroup: {
    updateOrdering: "onlineStoreGroup/updateOrdering",
  },
  getGiftCards: "giftCard/getGiftCards",
  giftCard: {
    save: "giftCard/save",
    getById: "giftCard/getGiftCardById",
    delete: "giftCard/deleteGiftCard",
    get: "giftCard/get",
    filter: "giftCard/filter",
  },
  fulfillment: {
    filter: "fulfillment/filter",
  },
  pos: {
    applyDiscountToOrder: "pos/applyDiscountToOrder",
    removeDiscountFromOrder: "pos/removeDiscountFromOrder",
    openCashDrawer: "pos/openCashDrawer",
    reimburseCanpayPayment: "pos/reimburseCanpayPayment",
    createCreditMemoFromPayment: "pos/createCreditMemoFromPayment",
    executeCanpayPayment: "pos/executeCanpayPayment",
    executeDebitPayment: "pos/executeDebitPayment",
    executeOverrideDebitPayment: "pos/executeOverrideDebitPayment",
    fulfillmentPrint: "pos/fulfillmentPrint",
    removeClutchDiscountFromOrder: "pos/removeClutchDiscountFromOrder",
    processPrices: "pos/processPrices"
  },
  order: {
    print: "order/print",
    fulfill: "order/fulfill",
    filter: "order/filter",
    getReceipt: "order/getReceipt",
    setLoyaltyCustomer: "order/setLoyaltyCustomer",
  },
  reports: {
    generate: "report/generate",
    // to deprecate
    getSalesTransactions: "report/getSalesTransactions",
    getInventoryTransactions: "report/getInventoryTransactions",
    getFinanceTransactions: "report/getFinanceTransactions",
    getCustomerTransactions: "report/getCustomerTransactions",
    getCouponTransactions: "report/getCouponTransactions",
    getEmployeeTransactions: "report/getEmployeeTransactions",
  },
  items: {
    filter: "item/filter",
    split: "item/split",
    mergeToOriginal: "item/mergeToOriginal",
    printLabel: "item/printLabel",
    getAvailableItemsForProduct: "item/getAvailableItemsForProduct",
    printStoreTransferLabel: "item/printStoreTransferLabel",
    updateItemPreOrderCount: "item/updateItemPreOrderCount",
  },
  returnItem: {
    save: "returnItem/saveReturnItem",
    delete: "returnItem/deleteReturnItem",
    getAll: "returnItem/getReturnItems",
    getById: "returnItem/getReturnItemById",
    getAdjacent: "returnItem/getAdjacent",
    postToJournal: "returnItem/postToJournal",
    printReturnItem: "returnItem/printReturnItem",
  },
  cashReconciliation: {
    save: "cashReconciliation/saveCashReconciliation",
    getCashReconciliations: "cashReconciliation/getCashReconciliations",
    getCashReconciliationById: "cashReconciliation/getCashReconciliationById",
    getCashReconciliationInfo: "cashReconciliation/getCashReconciliationInfo",
    post: "cashReconciliation/postCashReconciliation",
    getStationsWithActivity: "cashReconciliation/getStationsWithActivity",
  },
  inventoryCount: {
    save: "inventoryCount/saveInventoryCount",
    getinventoryCounts: "inventoryCount/getInventoryCounts",
    getinventoryCountById: "inventoryCount/getInventoryCountById",
    getItemsByBarcode: "inventoryCount/getItemsByBarcode",
    updateInventoryCountList: "inventoryCount/updateInventoryCountList",
    toggleArchiveInventoryCount: "inventoryCount/toggleArchiveInventoryCount",
    compareInventoryCount: "inventoryCount/compareInventoryCount",
    recalculateCompareInventoryCount: 'inventoryCount/recalculateCompareInventoryCount',
    confirmInventoryCount: "inventoryCount/confirmInventoryCount",
    getCompareToOptions: "inventoryCount/getCompareToOptions",
    setCompareTo: "inventoryCount/setCompareTo",
    closeInventoryCount: "inventoryCount/closeInventoryCount",
    checkMissingBatches: "inventoryCount/checkMissingBatches",
  },
  tag: {
    getAll: "tag/getAll",
  },
  loyalty: {
    lookup: "loyalty/lookup",
    getPointsCashConversionRate: "loyalty/getPointsCashConversionRate",
    reenroll: "loyalty/reenroll",
    optOut: "loyalty/optOut",
    getAvailableDiscounts: "loyalty/getAvailableDiscounts",
  },
  monitoring: {
    logEvent: "monitoring/logEvent",
  },
  brand: {
    getBrands: "brand/getBrands",
    getBrandById: "brand/getBrandById",
    deleteBrand: "brand/deleteBrand",
    saveBrand: "brand/saveBrand",
  }
};

module.exports = Apis;
