<template>
  <div class="layout">
    <div class="
        two-columns-layout
        two-columns-layout--responsive
        two-columns-layout--responsive-horizontal-space-10-forced
      ">
      <div class="enroll-text">

        Please ask to enroll this customer in our loyalty program to earn rewards. If they decline, opt them out
        to
        avoid asking again.
        <div class="button-container">
          <inline-button @click="() => enroll()">Enroll Customer</inline-button>
          <inline-button @click="() => optOut()" type="plain-danger">Opt Out</inline-button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CustomerNote",
  props: {
    customer: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      customerOptOut: "loyaltyModule/customerOptOut",
    }),
    enroll: function () {
      this.$emit('enroll');
    },
    optOut: function () {
      const payload = {
        payload: {
          _id: this.customer?._id
        },
        positiveCallback: (result) => {
          this.$emit('opted-out');
        },
        negativeCallback: (error) => {
          this.$emit('opted-out');
        },
      };

      this.customerOptOut(payload);
    },
  },
};
</script>

<style scoped lang="scss">
.enroll-text {
  text-align: center;
}

.button-container {
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
