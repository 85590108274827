import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import router from "@/router";
import Apis from "@/services/Apis";
import { defaultSelectedItem } from "@/views/Item/inputs";
const state = {
  items: [],
  item: { ...defaultSelectedItem },
  loadingStatus: {
    saveItem: false,
    getItemById: false,
    deleteItem: false,
    move: false
  },
  selectedFilter: undefined,
  selectedFilter2: undefined,
  itemMovementPopUp: { isOpen: false },
  sort: {
    'externalFields.group.name': 1,
    'externalFields.supplier.name': 1,
    'externalFields.product.name': 1,
    'externalFields.product.cannabisGramsUnit': 1,
  },
  pagination: { skip: 0, limit: 20 },
  filterButtons: [
    {
      isSelected: false,
      _id: 0,
      label: "All",
      name: "all",
      showAll: false,
      filter: {
        isReceived: true,
        isDeleted: false,
      },
    },
    {
      isSelected: true,
      _id: 1,
      label: "Cannabis",
      name: "cannabis",
      showAll: false,
      filter: {
        "externalFields.group.isCannabis": true,
        quantityOnHand: { $gt: 0 },
        isReceived: true,
        isDeleted: false,
      },
    },
    {
      isSelected: false,
      _id: 2,
      label: "Medical",
      name: "cannabis-medical",
      showAll: false,
      filter: {
        "externalFields.group.isCannabis": true,
        batchType: { $in: ["medical", "medical-and-recreational"] },
        quantityOnHand: { $gt: 0 },
        isReceived: true,
        isDeleted: false,
      },
    },
    {
      isSelected: false,
      _id: 3,
      label: "Recreational",
      name: "cannabis-recreational",
      showAll: false,
      filter: {
        "externalFields.group.isCannabis": true,
        batchType: { $in: ["recreational", "medical-and-recreational"] },
        quantityOnHand: { $gt: 0 },
        isReceived: true,
        isDeleted: false,
      },
    },
    {
      isSelected: false,
      _id: 4,
      label: "Non Cannabis",
      name: "non-cannabis",
      showAll: false,
      filter: {
        "externalFields.group.isCannabis": false,
        quantityOnHand: { $gt: 0 },
        isReceived: true,
        isDeleted: false,
      },
    },
  ],
  filterButtons2:[
    {
      isSelected: false,
      _id: 10,
      label: "All",
      name: "all",
      showAll: false,
      filter: {
        quantityOnHand: { $gte: 0 },
        isDeleted: false,
      },
    },{
      isSelected: true,
      _id: 11,
      label: "Active",
      name: "active",
      showAll: false,
      filter: {
        isArchived: false,
        isDeleted: false,
        isReceived: true,
        quantityOnHand: { $gt: 0 },
      },
    }
  ],
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setItems: (state, payload) => {
    state.items = payload;
  },
  setItem: (state, payload) => {
    if (payload._id === "NEW") state.item = { ...defaultSelectedItem };
    else state.item = { ...defaultSelectedItem, ...payload };
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedFilter2: (state, payload) => {
    state.selectedFilter2 = payload;
  },
  setItemMovementPopUp: (state, payload) => {
    state.itemMovementPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateFilterButtons: (state, payload) => {
    state.filterButtons = payload;
  },
  updateFilterButtons2: (state, payload) => {
    state.filterButtons2 = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  itemMovementPopUp: (state) => state.itemMovementPopUp,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  filterButtons: (state) => state.filterButtons,
  filterButtons2: (state) => state.filterButtons2,
  initialFilter: (state) => state.initialFilter,
};

const actions = {

  saveItem: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveItem", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveItem,
      payload.item,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          if (payload.negativeCallback) {
            payload.negativeCallback(error);
          }
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  move: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: " move", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.move,
      payload.body,
      function (response, error) {
        commit("setLoadingStatus", { api: " move", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Moved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          if (payload.negativeCallback) {
            payload.negativeCallback(error);
          }
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteItem: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteItem", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteItem,
      payload.item,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteItem", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  split: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "split", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.items.split,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "split", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Split", type: "success" },
            { root: true }
          );
          commit("setItem", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  mergeToOriginal: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "mergeToOriginal", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.items.mergeToOriginal,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "mergeToOriginal", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Merged to original", type: "success" },
            { root: true }
          );
          commit("setItem", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getItemsByBatch: (_, { batchId }) => {
    return new Promise((resolve, reject) => {
      const payload = {
        filter: {
          batchId,
        },
      };
      let url = `${Apis.backendBaseUrl}${Apis.items.filter}`;
      HttpService.post(url, payload, function (response, error) {
        let items = [];
        if (error) {
          reject(error);
        }
        if (response) {
          items = response.data.body;
        }
        resolve(items);
      });
    });
  },

  printStoreTransferLabel: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Apis.backendBaseUrl}${Apis.items.printStoreTransferLabel}`;
      HttpService.post(url, payload, function (response, error) {
        if (error) {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          reject(error);
        } else {
          commit(
            "setMessage",
            { text: "Label Printed", type: "success" },
            { root: true }
          );
          resolve();
        }
      });
    });
  },
  getAvailableInventoryForProduct: ({ commit, state }, payload) => {
    let url = `${Apis.backendBaseUrl}${Apis.items.getAvailableItemsForProduct}?productId=${payload.productId}`;

    HttpService.get(url, function (response, error) {
      var items = {};
      if (response) {
        items = response.data.body;

        commit("setItems", items);
      }
    });
  },
  getItemById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getItemById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getItemById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getItemById", status: false });
        if (response) {
          commit("setItem", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  printLabel: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "printLabel", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.items.printLabel,
      { _id: payload._id, labelPrinter: payload.labelPrinter },
      function (response, error) {
        commit("setLoadingStatus", { api: "printLabel", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          if (payload.negativeCallback) {
            payload.negativeCallback(error);
          }
          else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
          }
          console.log(error);
        }
      }
    );
  },
  updateItemPreOrderCount: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "updateItemPreOrderCount", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.items.updateItemPreOrderCount,
      { payload: payload },
      function (response, error) {
        commit("setLoadingStatus", { api: "updateItemPreOrderCount", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  updateItemPreOrderCountAsync: async ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "updateItemPreOrderCount", status: true });
    const res = await HttpService.postAsync(
      Apis.backendBaseUrl + Apis.items.updateItemPreOrderCount,
      { payload: payload }
    );
    commit("setLoadingStatus", { api: "updateItemPreOrderCount", status: false });
    return res;
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setItemMovementPopUp: ({ commit, state }, payload) => {
    commit("setItemMovementPopUp", payload);
  },
  setItem: ({ commit, state }, payload) => {
    commit("setItem", payload);
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentItem", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "item/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjacentItem", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateFilterButtons: ({ commit, state }, payload) => {
    commit("updateFilterButtons", payload)
  },
  updateFilterButtons2: ({ commit, state }, payload) => {
    commit("updateFilterButtons2", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
