import { managementRoles, inventoryRoles } from "@/constants/roles";

export const isManager = (employee, currentStoreId) => {
  try {
    const role = employee.role;
    let isManager = managementRoles.includes(role);
    if (isManager) return true;

    const stores = employee.stores;
    const foundStore = stores.find((s) => s.store._id == currentStoreId);

    if (foundStore) {
      isManager = managementRoles.includes(foundStore.role);

      if (isManager) return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

export const isInventoryManager = (employee, currentStoreId) => {
  try {
    const role = employee.role;
    let isManager = managementRoles.includes(role);
    let isInventoryManager = inventoryRoles.includes(role);
    if (isManager || isInventoryManager) return true;

    const stores = employee.stores;
    const foundStore = stores.find((s) => s.store._id == currentStoreId);

    if (foundStore) {
      isManager = managementRoles.includes(foundStore.role);
      isInventoryManager = inventoryRoles.includes(foundStore.role);

      if (isManager || isInventoryManager) return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};
