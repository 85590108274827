// Customer Editor Inputs

import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";
import { parseSrn } from "@/lib/srnHelper";

const getListOfLicenseExprationYears = function () {
  const listOfYears = [];
  let currentYear = new Date().getFullYear();
  for (var i = 0; i < 20; i++) {
    listOfYears.push({
      label: currentYear,
      code: currentYear,
    });
    currentYear++;
  }
  return listOfYears;
};

// Page Header
export const pageHeader = {
  title: "Customer",
  isEditorView: true,
  cancelPath: "customers",
  addPath: "customerEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Customer",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "customerEditor",
};

// Search By Options:
// • Id
// • SRN #
// • Phone
// • Cell
// • Name (lastName + name) (default index)
// • Email

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Full Name",
    code: "fullName",
    field: { type: "input", label: "Full Name" },
    isPartial: false,
  },
  // {
  //   label: "Last Name",
  //   code: "lastName",
  //   field: { type: "input", label: "Last Name" },
  //   isPartial: true,
  // },
  // {
  //   label: "First Name",
  //   code: "name",
  //   field: { type: "input", label: "First Name" },
  //   isPartial: true,
  // },
  {
    label: "Email",
    code: "email",
    field: { type: "input", label: "Email" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  {
    label: "Patient SRN #",
    code: "srn",
    field: { type: "input", label: "SRN #" },
    isPartial: true,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
  {
    label: "Caregiver SRN #",
    code: "caregiverSrn",
    field: { type: "input", label: "Caregiver SRN #" },
    isPartial: true,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
  {
    label: "Phone",
    code: "phone",
    field: { type: "input", label: "Phone" },
    isPartial: true,
  },
  {
    label: "Cell",
    code: "cell",
    field: { type: "input", label: "Phone" },
    isPartial: true,
  },
];

// Columns (in order):
// • _id (column header clickable)
// • lastName+name (column header clickable)
// • srn
// • state
// • verificationDate
// • city
// • phone
// • cell
// • amountDue
export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Last Name",
    id: "lastName",
    size: "medium",
  },
  {
    title: "First Name",
    id: "name",
    size: "medium",
  },
  {
    title: "Email",
    id: "email",
    size: "large",
  },
  {
    title: "Patient SRN #",
    id: "srn",
    size: "medium",
  },
  {
    title: "Caregiver SRN #",
    id: "caregiverSrn",
    size: "medium",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
    size: "medium",
  },
  {
    title: "Verification Date",
    id: "verificationDate",
    type: "date",
    size: "medium",
  },
  {
    title: "Phone",
    id: "phone",
    size: "medium",
  },
  {
    title: "Cell",
    id: "cell",
    size: "medium",
  },
  {
    title: "Amount Due",
    id: "amountDue",
    prefix: "$",
    size: "medium",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const columnHeadersPos = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Last Name",
    id: "lastName",
  },
  {
    title: "First Name",
    id: "name",
  },
  {
    title: "Email",
    id: "email",
    size: "large",
  },
  {
    title: "Patient SRN #",
    id: "srn",
  },
  {
    title: "Caregiver SRN #",
    id: "caregiverSrn",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
  },
  {
    title: "Verification Date",
    id: "verificationDate",
    type: "date",
  },
  {
    title: "Phone",
    id: "phone",
  },
  {
    title: "Cell",
    id: "cell",
  },
  {
    title: "Amount Due",
    id: "amountDue",
    prefix: "$",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Last Name",
    id: "lastName",
  },
  {
    title: "First Name",
    id: "name",
  },
  {
    title: "Email",
    id: "email",
    size: "large",
  },
  {
    title: "Patient SRN #",
    id: "srn",
  },
  {
    title: "CaregiverSRN #",
    id: "caregiverSrn",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
  },
  {
    title: "Verification Date",
    id: "verificationDate",
    type: "date",
  },
  {
    title: "Phone",
    id: "phone",
  },
  {
    title: "Cell",
    id: "cell",
  },
  {
    title: "Amount Due",
    id: "amountDue",
    prefix: "$",
  },
  {
    title: "Id",
    id: "_id",
  },
];

// Customer Information - Main Section
export const customerInfoInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "accountType",
        placeholder: "Select Account Type",
        type: "select",
        title: "Select Account Type *",
        options: Constants.listOfSaleType,
      },
      {
        id: "salutation",
        placeholder: "Select Salutation",
        type: "select",
        title: "Salutation",
        options: Constants.listOfSalutations,
      },
      {
        id: "name",
        placeholder: "First Name",
        type: "input",
        title: "First Name",
        required: true,
        focus: true,
      },
      {
        id: "middleName",
        placeholder: "Middle Name",
        type: "input",
        title: "Middle Name",
      },
      {
        id: "lastName",
        placeholder: "Last Name",
        type: "input",
        title: "Last Name",
        required: true,
      },
      {
        id: "pronoun",
        placeholder: "Pronoun",
        type: "input",
        title: "Pronoun",
      },
      {
        id: "phone",
        placeholder: "Phone",
        type: "mask",
        title: "Phone",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "preferredName",
        placeholder: "Preferred Name",
        type: "input",
        title: "Preferred Name",
      },
      {
        id: "email",
        type: "input",
        title: "Email *",
        placeholder: "Email",
      },

      {
        id: "country",
        placeholder: "Country",
        type: "select",
        title: "Country",
        options: Constants.countries,
      },
      {
        id: "state",
        placeholder: "State",
        type: "select",
        title: "State",
        options: Constants.listOfStates,
        required: true,
      },
      {
        id: "isPatient",
        placeholder: "Is Patient",
        type: "checkBox",
        title: "Is Patient",
      },
      {
        id: "isCaregiver",
        placeholder: "Is Caregiver",
        type: "checkBox",
        title: "Is Caregiver",
      },
      {
        id: "isMinor",
        placeholder: "Is Minor",
        type: "checkBox",
        title: "Is Minor",
      },
      {
        id: "isOapp",
        placeholder: "Is OAPP",
        type: "checkBox",
        title: "Is OAPP",
      },
      {
        id: "dob",
        placeholder: "Date of Birth",
        type: "calendar-dob",
        title: "Date of Birth",
        dateFormat: "dd/mm/yyyy",
        showTime: false,
      },
      {
        id: "amountDue",
        placeholder: "Amount due",
        type: "money",
        title: "Amount Due $",
        prefix: "$",
        disabled: true,
      },
    ],
  },
];

// Address Section
export const addressInputs = [
  {
    id: "address",
    placeholder: "Address",
    type: "input",
    title: "Address",
    class: "grid-row-two-column",
  },
  {
    id: "city",
    placeholder: "City",
    type: "input",
    title: "City",
  },
  {
    id: "zip",
    placeholder: "Zip",
    type: "mask",
    title: "Zip",
    mask: Constants.inputMasks.defaultZip,
  },
  {
    id: "county",
    placeholder: "County",
    type: "input",
    title: "County",
  },
  {
    id: "licenseNumber",
    placeholder: "License Number",
    type: "input",
    title: "License Number",
  }
];

// Patient Verification Section
export const verificationInputs = [
  {
    id: "srn",
    placeholder: "SRN #",
    type: "srn",
    title: "Patient SRN #",
  },
  {
    id: "verificationExpirationDate",
    placeholder: "Expiration Date",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "verificationDate",
    placeholder: "Verification date",
    type: "text",
    title: "Verification date",
  },
  {
    id: "verifiedBy",
    placeholder: "Verified by",
    type: "name",
    title: "Verified by",
    disabled: true,
  },
  {
    id: "privacyDocExpDate",
    placeholder: "Privacy Doc Expiration Date",
    type: "calendar",
    title: "Privacy Doc Expiration Date",
  },
  {
    id: "cannabisMaximumPeriod",
    placeholder: "Cannabis Maximum/Period",
    type: "money",
    title: "Cannabis Maximum/Period",
    prefix: "g",
    precision: Constants.numericInput.defaultWeightPrecision,
  },
  {
    id: "stateCannabisLimit",
    placeholder: "State Cannabis Limit",
    type: "weight",
    unit: "g",
    title: "State Cannabis Limit",
    disabled: true,
  },
  {
    id: "dfCannabisLimit",
    placeholder: "DF Cannabis Limit",
    type: "weight",
    unit: "g",
    title: "DF Cannabis Limit",
    disabled: true,
  },
  {
    id: "physicianSrn",
    placeholder: "Physician SRN #",
    type: "input",
    title: "Physician SRN #",
  },
  {
    id: "physicianName",
    placeholder: "Physician Name",
    type: "input",
    title: "Physician Name",
  },
];

// Caregiver Verification Section
export const verificationCaregiverInputs = [
  {
    id: "caregiverSrn",
    placeholder: "Caregiver SRN #",
    type: "srn",
    title: "Caregiver SRN #",
  },
  {
    id: "caregiverVerificationExpirationDate",
    placeholder: "Expiration Date",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "caregiverVerificationDate",
    placeholder: "Verification date",
    type: "text",
    title: "Verification date",
  },
  {
    id: "caregiverVerifiedBy",
    placeholder: "Verified by",
    type: "name",
    title: "Verified by",
    disabled: true,
  },
  {
    id: "privacyDocExpDate",
    placeholder: "Privacy Doc Expiration Date",
    type: "calendar",
    title: "Privacy Doc Expiration Date",
    disabled: true,
  },
];


export const loyaltyProgramInputs = [
  {
    id: "loyaltyInfo.cardNumber",
    type: "input",
    title: "Card Number",
  }
]

// Sale Discounts & Discount Categories Section
export const saleDiscountsInputs = [
  {
    id: "saleDiscountPercentage",
    placeholder: "Sale Discount",
    type: "number",
    title: "Sale Discount Percent",
    suffix: "%",
    precision: Constants.numericInput.percentagePrecision,
  },
  {
    id: "isEmployee",
    type: "checkBox",
    title: "Is Employee",
  },
  {
    id: "isVeteran",
    type: "checkBox",
    title: "Is Veteran",
  },
  {
    id: "isCCP",
    type: "checkBox",
    title: "Is CCP",
  },
  {
    id: "verifiedNotes",
    type: "textarea",
    title: "Notes",
    placeholder: "Notes",
    class: "flex-break-row grid-notes",
  },
];

export const dfPointInputs = [
  {
    id: "isPointTrackingActive",
    type: "checkBox",
    title: "Enable Point Tracking",
  },
  {
    id: "pointsLevel1",
    type: "number",
    title: "Points (Level 1)",
    sigDigits: 2,
    disabled: true,
  },
  {
    id: "pointsLevel2",
    type: "number",
    title: "Points (Level 2)",
    sigDigits: 2,
    disabled: true,
  },
  {
    id: "pointsLevel3",
    type: "number",
    title: "Points (Level 3)",
    sigDigits: 2,
    disabled: true,
  },
]


export const addCaregiverInput = [
  {
    id: "caregiver",
    placeholder: "Group",
    type: "search-btn",
    title: "Caregivers",
    label: "name",
    buttonOnly: true,
    entity: Entities.CUSTOMER,
    columnHeaders: searchColumnHeaders,
    filterCriteria: filterCriteria,
    hide: false,
    filter: {
      isCaregiver: true,
    },
  },
];

// Customer's Caregiver Section
export const customerCaregiverInputs = [
  {
    id: "srn",
    type: "text",
    title: "SRN #",
    disabled: true,
  },
  {
    id: "name",
    type: "text",
    title: "First Name",
    disabled: true,
  },
  {
    id: "lastName",
    type: "text",
    title: "Last Name",
    disabled: true,
  },
  {
    id: "verificationDate",
    type: "date",
    title: "Verification date",
    disabled: true,
  },
  {
    id: "verifiedBy",
    type: "name",
    title: "Verified by",
    disabled: true,
  },
  {
    id: "privacyDocExpDate",
    placeholder: "Privacy Doc Expiration Date",
    type: "calendar",
    title: "Privacy Doc Expiration Date",
  },
];

// Notes Section
export const notesInputs = [
  {
    id: "noteType",
    type: "select",
    title: "Note Type",
    placeholder: "Select Role",
    options: Constants.listOfCustomerNoteTypes,
  },
  {
    id: "hours",
    type: "number",
    title: "Hours",
    precision: 2,
    placeholder: "Hours",
  },
  {
    id: "isFollowedUp",
    type: "checkBox",
    title: "Followed up",
  },
  {
    id: "followUpDate",
    placeholder: "Follow up date",
    type: "calendar",
    title: "Follow up date",
  },
  {
    id: "note",
    type: "textarea",
    title: "Notes",
    placeholder: "Notes",
    class: "flex-break-row",
  },
];

// Notifications Section
export const notificationInputs = [
  // {
  //   id: "isNotificationInvoicePrinted",
  //   type: "checkBox",
  //   title: "Print",
  //   checkboxLabel: "Print",
  // },
  {
    id: "isNotificationInvoiceEmailed",
    type: "checkBox",
    title: "Email",
    checkboxLabel: "Email",
  },
  // {
  //   id: "isNotificationInvoiceTexted",
  //   type: "checkBox",
  //   title: "Text",
  //   checkboxLabel: "Text",
  // },
];

// export const notificationTypes = ["Invoice", "Statement", "Alert"];
export const notificationTypes = ["Invoice"];

// Other Section
export const referralInputs = [
  {
    id: "deliveryCharge",
    type: "money",
    title: "Delivery Charge",
    precision: Constants.numericInput.moneyPrecision,
    prefix: "$",
  },
  {
    id: "creditLimit",
    placeholder: "Credit limit",
    type: "money",
    title: "Credit limit",
    prefix: "$",
  },
  {
    id: "creditTerm",
    placeholder: "Credit term",
    type: "money",
    title: "Credit term",
    prefix: "$",
  },
  {
    id: "creditHold",
    placeholder: "Credit hold",
    type: "checkBox",
    title: "Credit hold",
  },
  {
    id: "creditHoldBy",
    placeholder: "Credit hold by",
    type: "name",
    title: "Credit hold by",
    disabled: true,
  },
  {
    id: "creditHoldDate",
    placeholder: "Credit hold date",
    type: "date",
    title: "Credit hold date",
    disabled: true,
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedCustomer = {
  _id: "NEW",
  name: "",
  lastName: "",
  salutation: "",
  middleName: "",
  preferredName: "",
  preferredSalutation: "",
  pronoun: "",
  address: "",
  city: "",
  state: "IL",
  zip: "",
  county: "",
  phone: "",
  cell: "",
  email: "",
  notes: "",
  accountType: "recreational",
  dfCannabisLimit: 0,
  stateCannabisLimit: 0,
  amountDue: 0,
  creditLimit: 0,
  creditTerm: 0,
  creditHold: false,
  creditHoldBy: {},
  creditHoldDate: "",
  srn: "",
  caregiverSrn: "",
  dob: "",
  verificationExpirationDate: "",
  verificationDate: "",
  privacyDocExpDate: "",
  caregiverVerificationExpirationDate: "",
  caregiverVerificationDate: "",
  cannabisMaximumPeriod: 0,
  licenseNumber: "",
  licenseText: "",
  licenseExpirationMonth: "",
  licenseExpirationYear: "",
  physicianSrn: "",
  physicianName: "",
  saleDiscountPercentage: 0,
  isEmployee: false,
  isMinor: false,
  isOapp: false,
  isVeteran: false,
  isCCP: false,
  verifiedNotes: "",
  caregivers: [],
  isCaregiver: false,
  isPatient: false,
  isNotificationInvoicePrinted: true,
  isNotificationInvoiceEmailed: true,
  isNotificationInvoiceTexted: true,
  isNotificationStatementPrinted: true,
  isNotificationStatementEmailed: true,
  isNotificationStatementTexted: true,
  isNotificationAlertPrinted: true,
  isNotificationAlertEmailed: true,
  isNotificationAlertTexted: true,
  isPointTrackingActive: true,
  pointsLevel1: 0,
  pointsLevel2: 0,
  pointsLevel3: 0,
  ss: "",
  refferals: 0,
  referredBy: {},
  referralDiscount: 0,
  deliveryCharge: 0,
  lastOrderDate: "",
  noteList: [],
  isVerified: false,
  isActive: true,
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
  hasOnlineStoreCredentials: false,
  isMarketingOptIn: false,
};
