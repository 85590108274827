// Queue Editor
import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";

// Page Header
export const pageHeader = {
  title: "Queue",
  isEditorView: true,
  cancelPath: "queue",
  addPath: "queueCustomerEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Queue",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "queueCustomerEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
  label: "Queue Code",
  code: "code",
  field: { type: "input", label: "Queue Code" },
  isPartial: true,
},
  {
    label: "Name",
    code: "customerInfo.name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Last Name",
    code: "customerInfo.lastName",
    field: { type: "input", label: "Last Name" },
    isPartial: true,
  },
];

export const queueInputs = [
  {
    id: "customerInfo.name",
    type: "text",
    title: "Name",
    disabled: true,
  },
  {
    id: "customerInfo.lastName",
    type: "text",
    title: "Last Name",
    disabled: true,
  },
  {
    id: "orderType",
    type: "text",
    title: "Order Type",
    disabled: true,
  },
  {
    id: "createdAt",
    title: "Queued Date Time",
    disabled: true,
    type: "calendar",
    showTime: true,
  },
  {
    id: "customerInfo.country",
    type: "text",
    title: "Country",
    disabled: true,
  },
  {
    id: "customerInfo.state",
    type: "text",
    title: "State",
    disabled: true,
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "customerInfo.name",
    size: "medium",
  },
  {
    title: "Last Name",
    id: "customerInfo.lastName",
    size: "medium",
  },
  {
    title: "Queue ID",
    id: "code",
    size: "medium",
  },
  {
    title: "Customer",
    id: "customer._id",
    link: true,
    url: "/customers/customerEditor?id=",
    urlId: "customer._id",
    size: "auto"
  },
  {
    id: "orderType",
    title: "Order Type",
  },
  {
    id: "customerInfo.state",
    title: "State",
  },
  {
    title: "Date",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Pre-order",
    id: "hasPreOrder",
    type: "checkbox",
  },
  {
    id: "status",
    title: "Status",
    type: "label",
  },
];

export const filterButtons = [
  {
    isSelected: true,
    _id: 0,
    label: "All",
    name: "all",
    showAll: false,
  },
  {
    isSelected: false,
    _id: 1,
    label: "Medical",
    name: "medical",
    showAll: false,
    filter: {
      orderType: "medical",
    },
  },
  {
    isSelected: false,
    _id: 2,
    label: "Recreational",
    name: "recreational",
    showAll: false,
    filter: {
      orderType: "recreational",
    },
  },
];

export const columnHeadersPos = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    iconName: "folder-open",
    ariaLabel: "Open",
    // position: "sticky",
    disabled: (item) => {
      return item.status != "waiting";
    },
  },
  {
    id: "remove",
    type: "button",
    // position: "sticky",
    action: "removeItem",
    iconName: "trash",
    ariaLabel: "Remove",
  },
  {
    title: "Name",
    id: "customerInfo.name",
  },
  {
    title: "Last Name",
    id: "customerInfo.lastName",
  },
  {
    title: "Queue Code",
    id: "code",
  },
  {
    title: "Customer",
    id: "customer._id",
    link: true,
    url: "/customers/customerEditor?id=",
    urlId: "customer._id",
  },
  {
    id: "orderType",
    title: "Order Type",
  },
  {
    id: "customerInfo.state",
    title: "State",
  },
  {
    title: "Time In",
    id: "createdAt",
    type: "timeOnly",
  },
  {
    title: "Pre-order",
    id: "hasPreOrder",
    type: "checkbox",
  },
  {
    id: "status",
    title: "Status",
    type: "label",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Order Id",
    id: "_id",
  },
];

export const defaultQueue = {
  customerInfo: {},
};
