const state = {
  loadingStatus: {
  },
  selectedFilter: undefined,
  sort: { createdAt: -1 },
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined,
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = { 
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload);
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload);
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
