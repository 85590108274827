export const normalizeEntityBeforeSaving = (specialPricing) => {
  const entity = JSON.parse(JSON.stringify(specialPricing));
  if (entity.saleType && entity.saleType.code) {
    entity.saleType = entity.saleType.code;
  }
  if (entity.startDate) {
    entity.startDate = new Date(entity.startDate);
  }
  if (entity.endDate) {
    entity.endDate = new Date(entity.endDate);
  }

  if(entity.supplier){
    entity.supplierName = entity.supplier.name;
  }

  if(entity.group){
    entity.groupName = entity.group.name;
  }

  if(entity.brand){
    entity.brandName = entity.brand.name;
  }
  return entity;
};
