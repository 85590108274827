import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import Entities from "@/resources/Entities";
import {
  getEndpoint,
  entityName,
  entityApiRoute,
} from "@/utils/entity-mappers";
import store from "../store/index";

export const getEntityApiUrl = {
  [Entities.CUSTOMER]: [Apis.getCustomers],
  [Entities.EMPLOYEE]: [Apis.getEmployees],
  [Entities.GROUP]: [Apis.getGroups],
  [Entities.PRODUCT]: [Apis.getProducts],
  [Entities.ITEM]: [Apis.getItems],
  [Entities.MSOGROUP]: [Apis.getMsoGroups],
  [Entities.MSOSUPPLIER]: [Apis.getMsoSuppliers],
  [Entities.MSOPRODUCT]: [Apis.getMsoProducts],
  [Entities.ONLINESTOREGROUP]: [Apis.getOnlineStoreGroups],
  [Entities.MSOCHARTOFACCOUNT]: [Apis.getMsoChartOfAccounts],
  [Entities.CHARTOFACCOUNT]: [Apis.getChartOfAccounts],
  [Entities.ORDER]: [Apis.getOrders],
  [Entities.ORGANIZATION]: "",
  [Entities.PURCHASEORDER]: [Apis.getPurchaseOrders],
  [Entities.ROOM]: [Apis.getRooms],
  [Entities.SPECIALPRICING]: [Apis.getSpecialPricings],
  [Entities.STORE]: [Apis.getStores],
  [Entities.SUPPLIER]: [Apis.getSuppliers],
  [Entities.SUPPLIERAGENT]: [Apis.getSupplierAgents],
  [Entities.GIFTCARD]: [Apis.getGiftCards],
  [Entities.CREDITMEMO]: [Apis.getCreditMemos],
  [Entities.MSOCOUPON]: [Apis.getMsoCoupons],
  [Entities.COUPON]: [Apis.getCoupons],
  [Entities.PAYMENT]: [Apis.getPayments],
  [Entities.TAXSETUP]: [Apis.getTaxSetups],
  [Entities.QUEUE]: [Apis.getQueue],
  [Entities.FLIGHT]: [Apis.flights.get],
  [Entities.JOURNAL]: [Apis.getJournals],
  [Entities.TIMESHEET]: [Apis.timeSheets.get],
  [Entities.CASHRECONCILIATION]: [
    Apis.cashReconciliation.getCashReconciliations,
  ],
  [Entities.INVENTORYCOUNT]: [Apis.getInventoryCounts],
};

export const getCardinality = (payload) => {
  let url =
    Apis.backendBaseUrl +
    (entityApiRoute?.[payload.entity] || payload.entity) +
    "/getCardinality";

  const filter = makeFilter(payload);
  const filter2 = makeFilter({
    preFilter: payload.preFilter2,
    filterCriteria: payload.filterCriteria2,
    preProcessFilterValue: payload.preProcessFilterValue2,
    isPartial: payload.isPartial2,
    filterValue: payload.filterValue2,
    filter: payload.filter,
    operation: payload.operation2
  });

  if (payload.additionalFilters) {
    Object.keys(payload.additionalFilters).forEach((k) => {
      payload.filter[k] = payload.additionalFilters[k];
    });
  }

  const filterPayload = {
    filter: { ...filter, ...filter2 },
    sort: payload.sort,
    limit: payload.limit ? payload.limit : undefined,
    skip: payload.skip ? payload.skip : undefined,
  };

  // if (payload.stampedIn) {
  //   url += `&stampedIn=${payload.stampedIn}`;
  // }
  // if (payload.stampedOut) {
  //   url += `&stampedOut=${payload.stampedOut}`;
  // }

  HttpService.post(url, filterPayload, function (response, error) {
    if (response) {
      if (payload && payload.positiveCallback) {
        payload.positiveCallback(response.data.body);
      }
    } else {
      store.dispatch(
        "setMessage",
        { text: error.message, type: "error" },
        { root: true }
      );
    }
  });
};

const makeFilter = ({ filter: inputFilter, preFilter, filterCriteria, preProcessFilterValue, isPartial, filterValue: inputFilterValue, operation }) => {
  let filter = JSON.parse(JSON.stringify(inputFilter || {}));
  let filterValue = inputFilterValue;

  if (preFilter) {
    // TODO make sure these filters work
    // url += "&" + preFilter;
  } else if (filterCriteria && filterValue) {
    if (preProcessFilterValue) {
      filterValue = preProcessFilterValue(filterValue);
    }
    if (isPartial) {
      filter[
        filterCriteria
      ] = `/${filterValue.toString()}/`;
    } else {
      if (operation) {
        filter[filterCriteria] = operation(filterValue);
      } else {
        filter[filterCriteria] = filterValue.toString();
      }
    }
  }

  return filter;
}

export const searchEntities = (payload) => {
  let url = `${Apis.backendBaseUrl}${payload.entity}/filter`;
  const filter = makeFilter(payload);
  const filter2 = makeFilter({
    preFilter: payload.preFilter2,
    filterCriteria: payload.filterCriteria2,
    preProcessFilterValue: payload.preProcessFilterValue2,
    isPartial: payload.isPartial2,
    filterValue: payload.filterValue2,
    operation: payload.operation2
  });

  // if (payload.skip >= 0) {
  //   url += `&skip=${payload.skip}`;
  // }
  // if (payload.limit >= 0) {
  //   url += `&limit=${payload.limit}`;
  // }
  // TODO make sure these filters work
  // if (payload.itemFilter) {
  //   url += `&itemFilter=${payload.itemFilter}`;
  // }
  // if (payload.type) url += `&type=${payload.type}`;

  if (payload.additionalFilters) {
    Object.keys(payload.additionalFilters).forEach((k) => {
      //url += `&${k}=${payload.additionalFilters[k]}`;
      filter[k] = payload.additionalFilters[k];
    });
  }

  const filterPayload = {
    filter: { ...filter, ...filter2 },
    sort: payload.sort,
    limit: payload.limit ? payload.limit : undefined,
    skip: payload.skip ? payload.skip : undefined,
  };

  HttpService.post(url, filterPayload, function (response, error) {
    if (response) {
      store.dispatch("setIsLoading", false);
      if (payload && payload.positiveCallback) {
        payload.positiveCallback(response.data.body);
      }
    } else {
      const message = error?.message || "Error Fetching Data";
      store.dispatch(
        "setMessage",
        { text: message, type: "error" },
        { root: true }
      );
    }
    store.dispatch("setIsLoading", false);
  });
};

export const getEntity = (payload, entity) => {
  store.dispatch("setIsLoading", true);
  const url = getEndpoint(entity, "get") + "?id=" + payload.id;
  HttpService.get(url, function (response, error) {
    if (response) {
      if (payload && payload.positiveCallback) {
        payload.positiveCallback(response.data.body);
      }
    } else {
      store.dispatch(
        "setMessage",
        { text: error.message, type: "error" },
        { root: true }
      );
    }
  });
};

export const getAdjacent = (payload, entity) => {
  const mode = payload.mode ? payload.mode : "next";
  var url =
    Apis.backendBaseUrl + entity + "/" + Apis.getAdjacent + "?mode=" + mode;
  if (payload.createdAt)
    url = url + "&date=" + payload.createdAt + "&_id=" + payload._id;
  HttpService.get(url, function (response, error) {
    if (response) {
      if (payload && payload.positiveCallback) {
        payload.positiveCallback(response.data.body);
      }
    } else {
      store.dispatch(
        "setMessage",
        { text: error.message, type: "error" },
        { root: true }
      );
    }
  });
};

export const postEntity = (payload, entity) => {
  const url = getEndpoint(entity, "save");
  HttpService.post(
    Apis.backendBaseUrl + getEndpoint(entity, "save"),
    payload.data,
    function (response, error) {
      if (response) {
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        if (payload.negativeCallback) {
          payload.negativeCallback();
        }
        store.dispatch(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    }
  );
};

export const deleteEntity = (payload, entity) => {
  const url = getEndpoint(entity, "delete");
  HttpService.post(
    Apis.backendBaseUrl + getEndpoint(entity, "delete"),
    { _id: payload.id },
    function (response, error) {
      if (response) {
        if (payload.positiveCallback) {
          store.dispatch(
            "setMessage",
            { text: `${entityName[entity]} was deleted`, type: "success" },
            { root: true }
          );
          payload.positiveCallback(response.data.body);
        }
      } else {
        store.dispatch(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    }
  );
};

export const archiveEntity = (payload, entity, isArchived) => {
  HttpService.post(
    Apis.backendBaseUrl + getEndpoint(entity, "archive"),
    { mode: isArchived ? "unarchive" : "archive", id: payload.id, updatedAt: payload.updatedAt, _entity: entity },
    function (response, error) {
      if (response) {
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        store.dispatch(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    }
  );
};
